import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useState } from "react";
import { useAuthContext } from "src/auth/useAuthContext";
import { setSession } from "src/auth/utils";
import {
  getDeviceDataById,
  logoutUser,
  updateloginStatusApi,
} from "src/service";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  deviceInfo: "",
};

const reducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const LoginDevices = ({ type, item }) => {
  const [list, setList] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { user, logout, device } = useAuthContext();

  useEffect(() => {
    getDeviceHistoryById();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    return `${day}/${month}/${year}, ${formattedHours}:${minutes}${ampm}`;
  }

  const getDeviceHistoryById = async () => {
    const res = await getDeviceDataById(item?.id, {
      roles: type === "staff" ? type : null,
    });
    setList(res?.data?.data?.LoginDeviceMaintain);
  };

  const updateLogoutStatus = async (id) => {
    const deviceInfo = list?.find((item) => item?.id === id);
    const res = await updateloginStatusApi(id);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
      if (
        deviceInfo?.users_id === user?.id &&
        deviceInfo?.login_device_detail === device
      ) {
        logout();
      }
    }
    getDeviceHistoryById();
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Modal Name</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Logout Status</TableCell> */}
              <TableCell>Login Date & time</TableCell>
              <TableCell>Last active Date & time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.login_device_detail}</TableCell>
                {/* <TableCell>
                  {!item?.log_out_status ? (
                    <Button variant="contained" size="small" color="success">
                      Logged In
                    </Button>
                  ) : (
                    <Button variant="contained" size="small">
                      Logged Out
                    </Button>
                  )}{" "}
                </TableCell> */}
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={item?.log_out_status}
                    onClick={() => updateLogoutStatus(item.id)}
                  >
                    {item?.log_out_status ? "LoggedOut" : "LogOut"}
                  </Button>
                </TableCell>

                <TableCell>{formatDate(item?.created_at)}</TableCell>
                <TableCell>{formatDate(item?.updated_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LoginDevices;
