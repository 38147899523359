import { useCallback, useState, useEffect } from "react";
import {
  Tooltip,
  Button,
  CardHeader,
  Typography,
  Stack,
  Badge,
  IconButton,
  DialogContent,
  Dialog,
  Switch,
  FormControlLabel,
  CloseIcon,
  FormGroup,
  DialogActions,
  Autocomplete,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  TextField,
  Checkbox,
  Box,
  CardContent,
  Card,
  DialogTitle,
} from "@mui/material";

import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp";
import Image from "../../../../components/image";
import {
  updateBiodata,
  scanBiodataPy,
  gptBiodataPy,
  finalBiodataPy,
  deleteBiodataPy,
  scanBiodataHinduPy,
  gptBiodataHinduPy,
  finalBiodataHinduPy,
  deleteBiodataHinduPy,
  copyBioDataCountHistory,
  callBioData,
  callStatus,
  getDuplicateImages,
  updateGender,
  moveToData,
  approvedJainHinduBiodata,
  callConnectedType,
  restoredBioData,
  verifyCallBioData,
  doneCallBioData,
  getStateListAPI,
  getDetailList,
  savePartnerPreference,
  addScannerNotes,
  getNotes,
  restoredSuspendedBioData,
  viewAllCallHistoryData,
} from "src/service";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useSnackbar } from "../../../../components/snackbar";
import constant from "src/Constant";
import { useAuthContext } from "src/auth/useAuthContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useCopyImageWithId from "src/hooks/useCopyImageWithId";
import moment from "moment";
// import Dialog from "src/theme/overrides/Dialog";
// ----------------------------------------------------------------------

export default function ScannerBioDataUserCard({
  selectedId,
  handleToggleAll,
  bioData,
  type,
  filterDetail,
  noteOptions,
  handleApproveDisapproveStatus,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [errors, setErrors] = useState({});
  const [openDuplicateImage, setOpenDuplicateImage] = useState(false);
  const [partnerPreference, setPartnerPreference] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  // const [selectedNotes, setSelectedNotes] = useState([]); // Selected checkboxes
  const [duplicateImages, setDuplicateImages] = useState([]);
  const [callHistoryModal, setCallHistoryModal] = useState(false);
  const [callHistory, setCallHistory] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const [newRow, setNewRow] = useState(bioData);
  const [date, setDate] = useState([]);

  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const contxt = useAuthContext();
  const [mobileValue, setMobileValue] = useState([]);
  const [viewImage, SetViewImage] = useState({ open: false, path: "" });

  const condition =
    type === "jain" ? newRow?.jain_image?.image : newRow?.hindu_image?.image;
  const { copyImageWithId, canvasRef } = useCopyImageWithId(
    `${type === "jain" ? "Jain" : "Hindu"}${bioData?.id}`
  );

  const callData =
    type === "jain" ? newRow?.jain_data_call : newRow?.hindu_data_call;

  const partnerPreferenceData =
    type === "jain"
      ? newRow?.jain_partner_preference
      : newRow?.hindu_partner_preference;

  const callConnectedTypeData =
    type === "jain"
      ? newRow?.jain_data_call_activity
      : newRow?.hindu_data_call_activity;
  const assignDataForCall =
    type === "jain"
      ? newRow?.jain_assign_data_for_call
      : newRow?.hindu_assign_data_for_call;

  const notesData =
    type === "jain" ? newRow?.jain_notes_data : newRow?.hindu_notes_data;

  const removeNotesData =
    type === "jain" ? newRow?.jain_data_notes : newRow?.hindu_data_notes;

  const [formData, setFormData] = useState({
    min_age: partnerPreferenceData?.min_age,
    max_age: partnerPreferenceData?.max_age,
    state: Array.isArray(partnerPreferenceData?.state)
      ? partnerPreferenceData?.state
      : JSON.parse(partnerPreferenceData?.state || "[]"),
    community: Array.isArray(partnerPreferenceData?.community)
      ? partnerPreferenceData?.community
      : JSON.parse(partnerPreferenceData?.community || "[]"),
    marital_status: Array.isArray(partnerPreferenceData?.marital_status)
      ? partnerPreferenceData?.marital_status
      : JSON.parse(partnerPreferenceData?.marital_status || "[]"),
    employed_in: Array.isArray(partnerPreferenceData?.employed_in)
      ? partnerPreferenceData?.employed_in
      : JSON.parse(partnerPreferenceData?.employed_in || "[]"),
    manglik_status: Array.isArray(partnerPreferenceData?.manglik_status)
      ? partnerPreferenceData?.manglik_status
      : JSON.parse(partnerPreferenceData?.manglik_status || "[]"),
    family_status: Array.isArray(partnerPreferenceData?.family_status)
      ? partnerPreferenceData?.family_status
      : JSON.parse(partnerPreferenceData?.family_status || "[]"),
    income: Array.isArray(partnerPreferenceData?.income)
      ? partnerPreferenceData?.income
      : JSON.parse(partnerPreferenceData?.income || "[]"),
    min_height: partnerPreferenceData?.min_height,
    max_height: partnerPreferenceData?.max_height,
  });

  const [notes, setNotes] = useState(notesData?.notes);

  const selectedNotesIds = notesData?.selected_notes_id
    ? JSON.parse(notesData.selected_notes_id)
    : [];

  const selectedNotesArray = Array.isArray(selectedNotesIds)
    ? selectedNotesIds
    : [];

  const [selectedNotes, setSelectedNotes] = useState(
    selectedNotesArray.map((id) => ({
      id,
      title: noteOptions?.notes?.find((note) => note.id === id)?.title || "",
    }))
  );

  const handleWheelZoom = useCallback(
    (event) => {
      if (event.shiftKey) {
        // Use Shift key as the modifier
        event.preventDefault();
        const zoomFactor = 0.1; // Adjust the zoom sensitivity

        setZoom((prevZoom) => {
          let newZoom;
          if (event.deltaY < 0) {
            newZoom = Math.min(prevZoom + zoomFactor, 3); // Zoom in
          } else {
            newZoom = Math.max(prevZoom - zoomFactor, 1); // Zoom out
            if (newZoom === 1) setPosition({ x: 0, y: 0 });
          }
          return newZoom;
        });
      }
    },
    [setZoom, setPosition]
  );

  const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level

  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.1, 1); // Ensures zoom doesn't go below 1
      if (newZoom === 1) setPosition({ x: 0, y: 0 });
      return newZoom;
    });
  };

  const handleDragStart = (e) => {
    if (zoom <= 1) return;
    setDragging(true);
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  useEffect(() => {
    const handleDrag = (e) => {
      if (!dragging || zoom <= 1) return;

      const newX = e.clientX - startPosition.x;
      const newY = e.clientY - startPosition.y;

      const maxOffsetX = ((zoom - 1) * window.innerWidth) / 2;
      const maxOffsetY = ((zoom - 1) * window.innerHeight) / 2;

      setPosition({
        x: Math.min(Math.max(newX, -maxOffsetX), maxOffsetX),
        y: Math.min(Math.max(newY, -maxOffsetY), maxOffsetY),
      });
    };

    const handleDragEnd = () => setDragging(false);

    if (dragging) {
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging, zoom, startPosition]);

  const handleDrag = (e) => {
    if (!dragging || zoom <= 1) return;

    const newX = e.clientX - startPosition.x;
    const newY = e.clientY - startPosition.y;

    const maxOffsetX = ((zoom - 1) * window.innerWidth) / 2;
    const maxOffsetY = ((zoom - 1) * window.innerHeight) / 2;

    setPosition({
      x: Math.min(Math.max(newX, -maxOffsetX), maxOffsetX),
      y: Math.min(Math.max(newY, -maxOffsetY), maxOffsetY),
    });
  };

  const handleDragEnd = () => setDragging(false);

  const handleCheckboxChange = (selectedNote) => {
    setSelectedNotes((prev) => {
      const isSelected = prev.some((note) => note.id === selectedNote.id);
      const updatedNotes = isSelected
        ? prev.filter((note) => note.id !== selectedNote.id) // Remove if unchecked
        : [...prev, selectedNote]; // Add if checked

      setNotes(updatedNotes.map((note) => note.title).join(" | "));

      return updatedNotes;
    });
  };

  const multipleButtons = (buttonType) => {
    if (type === "jain") {
      if (Array.isArray(newRow?.jain_data_call_activity)) {
        const btnObj = newRow.jain_data_call_activity.find(
          (item) => item.call_connected_type === buttonType
        );
        if (btnObj) {
          return btnObj.status === 1 ? "contained" : "outlined";
        }
      }
      return "outlined";
    }

    if (type === "hindu") {
      if (Array.isArray(newRow?.hindu_data_call_activity)) {
        const btnObj = newRow.hindu_data_call_activity.find(
          (item) => item.call_connected_type === buttonType
        );
        if (btnObj) {
          return btnObj.status === 1 ? "contained" : "outlined";
        }
      }
      return "outlined";
    }
  };

  useEffect(() => {
    if (selectedNotesArray.length > 0) {
      setSelectedNotes(
        selectedNotesArray.map((id) => ({
          id,
          title:
            noteOptions?.notes?.find((note) => note.id === id)?.title || "",
        }))
      );
    }
  }, [noteOptions]);

  const handleSubmit = async (id) => {
    try {
      const formObj = {
        id: id,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
        user_email: contxt?.user?.email,
        min_age: formData?.min_age,
        max_age: formData?.max_age,
        state: formData?.state,
        community: formData?.community,
        marital_status: formData?.marital_status,
        employed_in: formData?.employed_in,
        manglik_status: formData?.manglik_status,
        family_status: formData?.family_status,
        income: formData?.income,
        min_height: formData?.min_height,
        max_height: formData?.max_height,
      };

      const res = await savePartnerPreference(type, formObj);
      console.log(res?.data?.data);

      if (res?.data?.status) {
        const dataKey =
          type === "jain"
            ? "jain_partner_preference"
            : "hindu_partner_preference";

        setNewRow((prev) => ({
          ...prev,
          [dataKey]: {
            ...prev[dataKey],
            min_age: res?.data?.data?.min_age,
            max_age: res?.data?.data?.max_age,
            state: Array.isArray(res?.data?.data?.state)
              ? res?.data?.data?.state
              : JSON.parse(res?.data?.data?.state || "[]"),
            community: Array.isArray(res?.data?.data?.community)
              ? res?.data?.data?.community
              : JSON.parse(res?.data?.data?.community || "[]"),
            marital_status: Array.isArray(res?.data?.data?.marital_status)
              ? res?.data?.data?.marital_status
              : JSON.parse(res?.data?.data?.marital_status || "[]"),
            employed_in: Array.isArray(res?.data?.data?.employed_in)
              ? res?.data?.data?.employed_in
              : JSON.parse(res?.data?.data?.employed_in || "[]"),
            manglik_status: Array.isArray(res?.data?.data?.manglik_status)
              ? res?.data?.data?.manglik_status
              : JSON.parse(res?.data?.data?.manglik_status || "[]"),
            family_status: Array.isArray(res?.data?.data?.family_status)
              ? res?.data?.data?.family_status
              : JSON.parse(res?.data?.data?.family_status || "[]"),
            income: Array.isArray(res?.data?.data?.income)
              ? res?.data?.data?.income
              : JSON.parse(res?.data?.data?.income || "[]"),
            min_height: res?.data?.data?.min_height,
            max_height: res?.data?.data?.max_height,
          },
        }));

        setFormData({
          min_age: res?.data?.data?.min_age,
          max_age: res?.data?.data?.max_age,
          state: Array.isArray(res?.data?.data?.state)
            ? res?.data?.data?.state
            : JSON.parse(res?.data?.data?.state || "[]"),
          community: Array.isArray(res?.data?.data?.community)
            ? res?.data?.data?.community
            : JSON.parse(res?.data?.data?.community || "[]"),
          marital_status: Array.isArray(res?.data?.data?.marital_status)
            ? res?.data?.data?.marital_status
            : JSON.parse(res?.data?.data?.marital_status || "[]"),
          employed_in: Array.isArray(res?.data?.data?.employed_in)
            ? res?.data?.data?.employed_in
            : JSON.parse(res?.data?.data?.employed_in || "[]"),
          manglik_status: Array.isArray(res?.data?.data?.manglik_status)
            ? res?.data?.data?.manglik_status
            : JSON.parse(res?.data?.data?.manglik_status || "[]"),
          family_status: Array.isArray(res?.data?.data?.family_status)
            ? res?.data?.data?.family_status
            : JSON.parse(res?.data?.data?.family_status || "[]"),
          income: Array.isArray(res?.data?.data?.income)
            ? res?.data?.data?.income
            : JSON.parse(res?.data?.data?.income || "[]"),
          min_height: res?.data?.data?.min_height,
          max_height: res?.data?.data?.max_height,
        });

        enqueueSnackbar(res?.data?.message);
        setPartnerPreference(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.error("error ::::", error);
    }
  };

  const handleViewAllCallHistory = async () => {
    try {
      const obj = {
        user_id: contxt?.user?.id,
        source_id: newRow?.id,
      };

      const response = await viewAllCallHistoryData(type, obj);
      if (response?.status === 200) {
        console.log(response);
        console.log(response?.data?.data);
        setCallHistoryModal(true);
        setCallHistory(response?.data?.data);
      }
    } catch (error) {
      console.error("Error during handleAddNotes:", error);
      enqueueSnackbar("Something Went Wrong.", { variant: "error" });
    }
  };

  const handleAddNotes = async (id) => {
    const noteText = notes ? notes.trim() : "";

    const newErrors = {};

    if (!noteText) {
      newErrors.notes = "Notes field is required";
      setErrors(newErrors);
      return; // Stop execution if there's a validation error
    }
    try {
      setErrors({});

      const selectedNoteIds = selectedNotes.map((note) => note.id);

      const obj = {
        id,
        notes,
        selectedNoteIds,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
        user_email: contxt?.user?.email,
      };

      const response = await addScannerNotes(type, obj);

      if (response?.status === 200 && response?.data?.data) {
        const updatedNotes = response.data.data.notes;
        const dataKey =
          type === "jain" ? "jain_notes_data" : "hindu_notes_data";

        setNewRow((prev) => ({
          ...prev,
          [dataKey]: {
            ...prev[dataKey],
            notes: updatedNotes,
            user_name: response.data.data.user_name,
            updatedAt: response.data.data.updatedAt,
          },
        }));

        setNotes(updatedNotes);
        setOpenNotes(false);
        enqueueSnackbar("Successfully Updated", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleAddNotes:", error);
      enqueueSnackbar("Something Went Wrong.", { variant: "error" });
    }
  };

  const handleCallStatus = async (id, callType) => {
    try {
      let call_connected_status = 0,
        call_not_picked_status = 0,
        call_not_connected_status = 0;
      if (callType == "call_connected") {
        call_connected_status = 1;
      } else if (callType == "not_picked") {
        call_not_picked_status = 1;
      } else if (callType == "not_connected") {
        call_not_connected_status = 1;
      }

      const obj = {
        id,
        call_type: callType,
        call_connected_status: call_connected_status,
        call_not_picked_status: call_not_picked_status,
        call_not_connected_status: call_not_connected_status,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
      };

      const response = await callBioData(type, obj);

      if (response?.status === 200) {
        const dataKey = type === "jain" ? "jain_data_call" : "hindu_data_call";

        console.log(response?.data?.data);

        setNewRow((prev) => ({
          ...prev,
          [dataKey]: {
            ...prev[dataKey],
            call_connected_status: call_connected_status,
            call_not_picked_status: call_not_picked_status,
            call_not_connected_status: call_not_connected_status,
            call_connected_date: response?.data?.data?.call_connected_date,
            call_connected_time: response?.data?.data?.call_connected_time,
            id: response?.data?.data?.id,
            user_name: response?.data?.data?.user_name,
            is_verified: response?.data?.data?.is_verified,
            // is_verified:
            //   callType === "not_connected" || callType === "not_picked"
            //     ? 0
            //     : prev[dataKey]?.is_verified,
          },
        }));

        enqueueSnackbar("Successfully Updated", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };

  const handleVerifyStatus = async (id, status) => {
    try {
      if (!id) {
        enqueueSnackbar("Please connect the call first.", {
          variant: "error",
        });
        return;
      }

      const obj = {
        id,
        status: status,
        verified_by: contxt?.user?.nameEnglish,
      };

      const response = await verifyCallBioData(type, obj);

      if (response?.status === 200) {
        const dataKey = type === "jain" ? "jain_data_call" : "hindu_data_call";

        setNewRow((prev) => ({
          ...prev,
          [dataKey]: {
            ...prev[dataKey],
            is_verified: status,
            verified_by: response?.data?.data?.verified_by,
            verify_date_time: response?.data?.data?.verify_date_time,
          },
        }));

        enqueueSnackbar("Successfully Updated", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("Please connect the call first.", {
        variant: "error",
      });
    }
  };
  const handleDoneStatus = async (id) => {
    try {
      const obj = {
        id,
        verified_by: contxt?.user?.nameEnglish,
      };

      const response = await doneCallBioData(type, obj);

      if (response?.status === 200) {
        const dataKey = type === "jain" ? "jain_data_call" : "hindu_data_call";

        setNewRow((prev) => ({
          ...prev,
          [dataKey]: {
            ...prev[dataKey],
            is_verified: 1,
            is_done: 1,
            verified_by: response?.data?.data?.verified_by,
            verify_date_time: response?.data?.data?.verify_date_time,
          },
        }));

        enqueueSnackbar("Successfully Updated", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };

  const handleRestore = async (id) => {
    try {
      const response = await restoredBioData(type, {
        id: id,
        biodata_id: newRow?.id,
      });

      if (response?.status === 200) {
        const dataKey = type === "jain" ? "notesData" : "hindu_data_notes";

        setNewRow((prev) => ({
          ...prev,
          id: newRow?.id,
          remove_status: 2,
        }));
        enqueueSnackbar("Successfully Restored", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };
  
  const handleRestoreSuspend = async (id) => {
    try {
      const response = await restoredSuspendedBioData(type, {
        id: id,
        biodata_id: newRow?.id,
      });

      if (response?.status === 200) {
        setNewRow((prev) => ({
          ...prev,
          id: newRow?.id,
          suspend_status: 2,
        }));
        enqueueSnackbar("Successfully Restored", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };

  const onCallStatus = async (id, call_connected_type, status) => {
    try {
      const obj = {
        id,
        call_connected_type,
        status,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
      };

      const response = await callConnectedType(type, obj);

      if (response?.status === 200) {
        const dataKey =
          type === "jain"
            ? "jain_data_call_activity"
            : "hindu_data_call_activity";

        setNewRow((prev) => {
          const existingArray = Array.isArray(prev[dataKey])
            ? [...prev[dataKey]]
            : [];

          const index = existingArray.findIndex(
            (item) => item.call_connected_type === call_connected_type
          );

          if (index > -1) {
            existingArray[index] = {
              ...existingArray[index],
              status: status,
            };
          } else {
            existingArray.push({
              call_connected_type: call_connected_type,
              status: status,
            });
          }

          return {
            ...prev,
            [dataKey]: existingArray,
          };
        });

        enqueueSnackbar("Successfully Updated", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };

  // callStatus
  const onStatus = async (obj) => {
    setNewRow({ ...newRow, ...obj });
    obj.id = bioData?.id;
    let res = await updateBiodata(obj);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
  };

  const handleDuplicateImage = async (id) => {
    try {
      const response = await getDuplicateImages(type, { id: id });
      if (response?.status === 200) {
        setOpenDuplicateImage(true);
        setDuplicateImages(response?.data?.data);
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };

  const copyImageById = async (url) => {
    const image_id =
      type === "jain" ? newRow?.jain_image?.id : newRow?.hindu_image?.id;
    try {
      let obj = {
        id: newRow?.id,
        image_id: image_id,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
      };
      const response = await copyBioDataCountHistory(type, obj);
      if (response?.status === 200) {
        copyImageWithId(url);
        //  navigator.clipboard.writeText();
        // enqueueSnackbar(response?.data?.data, {
        //   variant: "success",
        // });
      }
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
    }
  };

  const handleGender = async (gender) => {
    try {
      let obj = {
        id: newRow?.id,
        gender: gender,
      };
      const response = await updateGender(obj, type);
      if (response?.status === 200) {
        enqueueSnackbar("Gender Updated Successfully", {
          variant: "success",
        });
        setNewRow((prev) => ({ ...prev, gender }));
      }
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
    }
  };

  const handleMoveToData = async (submit_type) => {
    try {
      const formObj = {
        id: [newRow?.id],
        user_id: contxt?.user?.id,
        submit_type: submit_type,
      };

      const response = await moveToData(type, formObj);
      if (response?.status === 200) {
        enqueueSnackbar("Moved Data Successfully!");
        setNewRow((prev) => ({
          ...prev,
          is_common: 0,
        }));
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleApprovedata = async () => {
    try {
      const formObj = {
        id: newRow?.id,
        status: 1,
      };

      const response = await approvedJainHinduBiodata(formObj, type);
      if (response?.status === 200) {
        enqueueSnackbar("Approved Successfully!");
        setNewRow((prev) => ({
          ...prev,
          is_jain_hindu_approved: 1,
        }));
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setNewRow(bioData);
  }, [bioData]);

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  const handleViewImage = (path) => {
    SetViewImage({ ...viewImage, open: !viewImage?.open, path: path });
  };

  useEffect(() => {
    let mobile = newRow?.mobiles
      ? newRow.mobiles.split(",").map((mobile) => mobile.trim())
      : [];
    setMobileValue(mobile || []);
  }, [newRow]);

  useEffect(() => {
    currentDate();
  }, []);

  const currentDate = () => {
    let today = new Date();
    setDate(today);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const handleCopyMobile = (i) => {
    copy(i);
    enqueueSnackbar("Copied!");
  };
  const handleCopyName = () => {
    copy(newRow.name);
    enqueueSnackbar("Copied!");
  };
  // send whatsapp msg
  const whatsappNow = (mobile) => {
    window.location.href = "https://wa.me/91" + `${maskNumber(mobile)}`;
  };

  const handleScan = async () => {
    try {
      enqueueSnackbar("Scanning...");
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await scanBiodataHinduPy(obj)
          : await scanBiodataPy(obj);
      const updatedData = res?.data?.data?.scanned_results?.find(
        (i) => i.id === bioData?.id
      );
      setNewRow({ ...newRow, ...updatedData, scan_status: 1 });
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleGpt = async () => {
    try {
      enqueueSnackbar("Gpt Scanning...");
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await gptBiodataHinduPy(obj)
          : await gptBiodataPy(obj);
      const updatedData = res?.data?.data?.scanned_results?.find(
        (i) => i.id === bioData?.id
      );
      setNewRow({ ...newRow, ...updatedData, gpt_status: 1 });
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleFinal = async () => {
    try {
      enqueueSnackbar("Final Scanning...");
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await finalBiodataHinduPy(obj)
          : await finalBiodataPy(obj);
      const updatedData = res?.data?.data?.scanned_results?.find(
        (i) => i.id === bioData?.id
      );
      setNewRow({ ...newRow, ...updatedData });
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await deleteBiodataHinduPy(obj)
          : await deleteBiodataPy(obj);
      setOpenConfirm(false);
      setNewRow({});
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const commonButtonStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "3px 13px",
    fontSize: "13px",
    minWidth: "auto",
    height: "25px",
    borderRadius: "0",
    fontWeight: "normal",
  };

  return (
    <div className={`offlineCard m-2`}>
      {/* d-flex justify-content-between */}
      <div
        className={` ${
          newRow?.[`${type}_image`]?.repeat_count !== 2
            ? "primaryBg"
            : "userAppBg"
        } light`}
        style={{
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        {callData?.is_verified === 1 && (
          <div
            style={{
              display: "inline-flex",
              fontWeight: "bold",
              fontSize: "14px",
              color: "white",
              padding: "3px 17px",
              backgroundColor: "red",
              position: "relative",
              width: "30%",
              borderTopLeftRadius: "15px",
              clipPath:
                "polygon(-100vw 0, 100% 0, calc(100% - 0.8em) 50%, 100% 100%, -100vw 100%)",
            }}
          >
            <img
              src="/assets/verified.png"
              alt="Verified"
              style={{
                width: "22px",
                height: "22px",
              }}
            />
            <span> Verified</span>
          </div>
        )}

        <div className="p-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Left Side: Typography */}
            {newRow?.id && (
              <Typography
                className={`${newRow?.is_online_user ? "ms-4" : ""}`}
                variant="subtitle1"
                sx={{ fontSize: "14px" }}
              >
                #
                <span
                  className={`fw-bold ${
                    newRow?.[`${type}_image`]?.repeat_count !== 2
                      ? "text-danger"
                      : "text-primary"
                  }`}
                >
                  {type === "jain" ? "J" : "H"}
                </span>
                {newRow?.id} &nbsp;({newRow?.[`${type}_image`]?.repeat_count})
              </Typography>
            )}

            {/* Right Side: Button */}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleToggleAll(newRow?.id)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "3px 13px",
                fontSize: "13px",
                minWidth: "auto",
                height: "25px",
                borderRadius: "0",
                fontWeight: "normal",
                background: selectedId?.find((i) => i === newRow?.id)
                  ? "#fff"
                  : "#32be8f",
                border: "1px solid #fff",
                borderRadius: "5px",
                color: selectedId?.find((i) => i === newRow?.id)
                  ? "#32be8f"
                  : "#fff",
              }}
            >
              {selectedId?.find((i) => i === newRow?.id)
                ? "Unselect"
                : "Select"}
            </Button>
          </div>

          {newRow?.name && (
            <Typography
              variant="subtitle1"
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              onClick={() => {
                handleCopyName(newRow?.name);
              }}
            >
              <AccountCircleIcon
                className="fs-16"
                style={{ marginRight: "5px" }}
              />
              {newRow?.name} <ContentCopyOutlinedIcon className="fs-16 ms-2" />
            </Typography>
          )}
          <div className="" style={{ fontSize: "14px" }}>
            {newRow?.confidence_score && (
              <span> Confidence Score = {newRow.confidence_score}</span>
            )}
          </div>
          {/* buttons */}
          <div className="pt-2 d-flex" style={{ flexWrap: "wrap", gap: "8px" }}>
            {(contxt?.user?.sections?.[
              type === "jain" ? "ScannerData" : "HinduScannerData"
            ]?.WorkFromHomeStaff ||
              contxt?.user?.sections?.[
                type === "jain" ? "ScannerData" : "HinduScannerData"
              ]?.isAdmin) && (
              <div className="button-small">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={commonButtonStyle}
                  onClick={() => {
                    const url = `/viewscannerdata/${type}/${newRow?.id}`;
                    window.open(url, "_blank");
                  }}
                >
                  View
                </Button>
              </div>
            )}
            {contxt?.user?.sections?.[
              type === "jain" ? "ScannerData" : "HinduScannerData"
            ]?.delete && (
              <div className="">
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => setOpenConfirm(true)}
                  style={commonButtonStyle}
                >
                  Delete
                </Button>
              </div>
            )}

            {newRow?.[`${type}_image`]?.repeat_count > 1 && (
              <div className="">
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => handleDuplicateImage(newRow?.id)}
                  style={commonButtonStyle}
                >
                  Duplicate Images
                </Button>
              </div>
            )}
            <Button
              className=""
              variant="contained"
              size="small"
              style={commonButtonStyle}
              onClick={() => setPartnerPreference(true)}
            >
              Partner Preference
            </Button>

            {newRow?.gender === "both" && (
              <>
                <button
                  className="btn btn-sm btn-light "
                  style={commonButtonStyle}
                  onClick={() => handleGender("male")}
                >
                  Male
                </button>
                <button
                  className="btn btn-sm btn-light"
                  style={commonButtonStyle}
                  onClick={() => handleGender("female")}
                >
                  Female
                </button>
              </>
            )}

            {newRow?.id &&
              newRow?.is_common === 1 &&
              newRow?.is_jain_hindu_approved === 0 &&
              (type === "jain" ? (
                <>
                  <button
                    className="btn btn-sm btn-light"
                    style={commonButtonStyle}
                    onClick={() => handleMoveToData("move_to_hindu")}
                  >
                    Move To Hindu
                  </button>

                  <button
                    className="btn btn-sm btn-success"
                    style={commonButtonStyle}
                    onClick={handleApprovedata}
                  >
                    Approve Jain
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-light"
                    style={commonButtonStyle}
                    onClick={() => handleMoveToData("move_to_jain")}
                  >
                    Move To Jain
                  </button>

                  <button
                    className="btn btn-sm btn-success"
                    style={commonButtonStyle}
                    onClick={handleApprovedata}
                  >
                    Approve Hindu
                  </button>
                </>
              ))}
          </div>
          {/* buttond ends */}
        </div>
      </div>
      <p
        style={{
          margin: 1,
          fontSize: "13px",
          fontWeight: "normal",
          paddingLeft: "7px",
        }}
      >
        {[
          newRow?.city && `${newRow?.city} (${newRow?.state})`,
          newRow?.country,
          newRow?.gender,
          newRow?.community,
          newRow?.sub_community,
          newRow?.marital_status,
          newRow?.employed_in,
          newRow?.dob,
          newRow?.height,
          newRow?.education,
          newRow?.country,
          newRow?.age,
          newRow?.family_status,
          newRow?.income,
          newRow?.physical_status,
          newRow?.mother_tongue,
        ]
          .filter(Boolean)
          .join(" | ")}
      </p>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
          fontSize: "13px",
          pl: 1,
          backgroundColor: "#CAFFFB",
        }}
      >
        Available in :- Biodata | Online User
      </Typography>
      {partnerPreferenceData && (
        <div
          style={{
            backgroundColor: "#FFFACD",
            color: "#000",
            paddingLeft: "7px",
          }}
          sx={{
            width: "100%",
            margin: 0,
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
          item
          md={12}
          sm={12}
          xs={12}
          lg={12}
        >
          <p style={{ margin: 1, fontSize: "13px" }}>
            <span style={{ fontWeight: "bold", color: "red" }}>
              {" "}
              Partner Preference -{" "}
            </span>{" "}
            {[
              partnerPreferenceData?.min_age && partnerPreferenceData?.max_age
                ? `${partnerPreferenceData.min_age} - ${partnerPreferenceData.max_age}`
                : "",

              ...(partnerPreferenceData?.state
                ? Array.isArray(partnerPreferenceData.state)
                  ? [partnerPreferenceData.state.join(", ")]
                  : [JSON.parse(partnerPreferenceData.state).join(", ")]
                : []),

              ...(partnerPreferenceData?.community
                ? Array.isArray(partnerPreferenceData.community)
                  ? [partnerPreferenceData.community.join(", ")]
                  : [JSON.parse(partnerPreferenceData.community).join(", ")]
                : []),

              ...(partnerPreferenceData?.employed_in
                ? Array.isArray(partnerPreferenceData.employed_in)
                  ? [partnerPreferenceData.employed_in.join(", ")]
                  : [JSON.parse(partnerPreferenceData.employed_in).join(", ")]
                : []),

              ...(partnerPreferenceData?.marital_status
                ? Array.isArray(partnerPreferenceData.marital_status)
                  ? [partnerPreferenceData.marital_status.join(", ")]
                  : [
                      JSON.parse(partnerPreferenceData.marital_status).join(
                        ", "
                      ),
                    ]
                : []),

              ...(partnerPreferenceData?.manglik_status
                ? Array.isArray(partnerPreferenceData.manglik_status)
                  ? [partnerPreferenceData.manglik_status.join(", ")]
                  : [
                      JSON.parse(partnerPreferenceData.manglik_status).join(
                        ", "
                      ),
                    ]
                : []),

              ...(partnerPreferenceData?.income
                ? Array.isArray(partnerPreferenceData.income)
                  ? [partnerPreferenceData.income.join(", ")]
                  : [JSON.parse(partnerPreferenceData.income).join(", ")]
                : []),

              ...(partnerPreferenceData?.family_status
                ? Array.isArray(partnerPreferenceData.family_status)
                  ? [partnerPreferenceData.family_status.join(", ")]
                  : [JSON.parse(partnerPreferenceData.family_status).join(", ")]
                : []),

              partnerPreferenceData?.min_height &&
              partnerPreferenceData?.max_height
                ? `${partnerPreferenceData.min_height} - ${partnerPreferenceData.max_height}`
                : "", // Format height range correctly
            ]
              .filter(Boolean) // Remove empty values
              .join(" | ")}
          </p>
        </div>
      )}
      <p
        style={{
          fontSize: "13px",
          paddingLeft: "7px",
          paddingTop: "2px",
        }}
      >
        {newRow?.upload_staff_name && (
          <div className="">
            {" "}
            <span style={{ color: "red" }}>Uploaded By -</span>
            {newRow?.upload_staff_name ?? "N/A"} (
            {moment(newRow?.upload_time, "YYYY-MM-DD").format("DD/MM/YYYY")}{" "}
            {newRow?.upload_real_time
              ? moment(newRow?.upload_real_time, "HH:mm:ss").format("hh:mm A")
              : "N/A"}
            )
          </div>
        )}
      </p>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item md={5} sm={12} xs={12} lg={5}>
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

          <div
            style={{
              width: "350px",
              height: "170px",
              maxWidth: "100%",
              maxHeight: "170px",
              borderRadius: "8px",
              display: "flex",
              overflow: "hidden", // Ensures no unwanted overflow
            }}
          >
            <Image
              onContextMenu={handleRightClick}
              alt={newRow.value}
              style={{
                paddingLeft: "10px",
                width: "100%", // Ensures image fits the wrapper
                height: "100%", // Ensures image maintains aspect ratio
                objectFit: "contain", // Prevents image from stretching
                borderRadius: "8px", // Matches the wrapper’s border-radius
              }}
              src={
                type === "jain"
                  ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                  : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
              }
              onClick={() => {
                handleViewImage(
                  type === "jain"
                    ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                    : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                );
              }}
            />
          </div>
        </Grid>

        <Grid item md={2} sm={12} xs={12} lg={2}>
          <ContentCopyOutlinedIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              copyImageById(
                type === "jain"
                  ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                  : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
              )
            }
            className="fs-16 ms-2"
          />
        </Grid>
        <Grid item md={5} sm={12} xs={12} lg={5}></Grid>
      </Grid>
      {newRow?.update_staff_name && (
        <div
          className="p-2"
          style={{
            fontSize: "13px",
          }}
        >
          <span style={{ color: "#0004FB" }}>Updated By: </span>{" "}
          {newRow?.update_staff_name ?? "N/A"} (
          {moment(newRow?.update_time, "YYYY-MM-DD").format("DD/MM/YYYY")}{" "}
          {newRow?.update_real_time
            ? moment(newRow?.update_real_time, "HH:mm:ss").format("hh:mm A")
            : "N/A"}
          )
        </div>
      )}{" "}
      <div className="p-2">
        {mobileValue && mobileValue.length > 0 ? (
          mobileValue.map((i, index) => (
            <div
              key={index}
              className="displayBtwPro d-flex justify-content-between align-items-center"
              style={{ height: "38px" }}
            >
              {/* Copy Mobile Button */}
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    padding: "3px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    handleCopyMobile(
                      maskNumber(typeof i === "object" ? i?.mobile : i)
                    )
                  }
                >
                  {maskNumber(typeof i === "object" ? i?.mobile : i)}
                  <ContentCopyOutlinedIcon className="fs-14 ms-1" />
                </Button>
              </div>

              {/* WhatsApp & Work Assign Buttons */}
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                {/* Work Assign Button */}
                <button
                  className="btn btn-primary btn-sm"
                  style={{
                    color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                    background: newRow?.whatsapp_status
                      ? "#525FFE"
                      : "transparent",
                    padding: "3px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                    height: "24px",
                    border: "1px solid #525FFE",
                  }}
                  onClick={() => {
                    onStatus({
                      whatsapp_status: !newRow?.whatsapp_status,
                      whatsapp_date_time: date,
                      whatsapp_action_id: contxt?.user?.id,
                    });
                  }}
                >
                  Work Assign
                </button>

                {/* WhatsApp Button */}
                <button
                  className="btn btn-success btn-sm"
                  style={{
                    padding: "3px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                    height: "24px",
                    backgroundColor: "#07C634",
                  }}
                  onClick={() => {
                    whatsappNow(newRow?.mobile);
                  }}
                >
                  <WhatsAppIcon
                    className="me-1"
                    style={{
                      fontSize: "17px",
                    }}
                  />{" "}
                  Whatsapp
                </button>
                {/* Call Button */}
                <button
                  className="btn btn-sm"
                  style={{
                    padding: "3px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                    height: "24px",
                    backgroundColor: "#0004fb",
                  }}
                  onClick={() => {
                    window.location.href = `tel:${maskNumber(
                      typeof i === "object" ? i?.mobile : i
                    )}`;
                  }}
                >
                  <i className="fa fa-phone text-white"></i>
                </button>
              </div>
            </div>
          ))
        ) : newRow?.mobile ? (
          <div
            className="displayBtwPro d-flex justify-content-between align-items-center"
            style={{ height: "38px" }}
          >
            <div>
              <Button
                className=""
                variant="outlined"
                size="small"
                style={{
                  padding: "3px 8px",
                  fontSize: "12px",
                  minWidth: "auto",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => handleCopyMobile(maskNumber(newRow?.mobile))}
              >
                {maskNumber(newRow?.mobile)}
                <ContentCopyOutlinedIcon className="fs-16 ms-2" />
              </Button>
            </div>

            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              {/* Work Assign Button */}
              <button
                className="btn btn-primary btn-sm"
                style={{
                  borderColor: "blue",
                  color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                  background: newRow?.whatsapp_status
                    ? "#525FFE"
                    : "transparent",
                  padding: "3px 8px",
                  fontSize: "12px",
                  minWidth: "auto",
                  height: "24px",
                  border: "1px solid #525FFE",
                }}
                onClick={() => {
                  onStatus({
                    whatsapp_status: !newRow?.whatsapp_status,
                    whatsapp_date_time: date,
                    whatsapp_action_id: contxt?.user?.id,
                  });
                }}
              >
                Work Assign
              </button>

              {/* WhatsApp Button */}
              <button
                className="btn btn-success btn-sm"
                style={{
                  backgroundColor: "#07C634",
                  padding: "3px 8px",
                  fontSize: "12px",
                  minWidth: "auto",
                  height: "24px",
                }}
                onClick={() => {
                  whatsappNow(newRow?.mobile);
                }}
              >
                <WhatsAppIcon /> Whatsapp
              </button>
            </div>
          </div>
        ) : null}

        {assignDataForCall && (
          <div
            className="mt-2 px-2 d-flex flex-column"
            style={{ fontSize: "14px", width: "100%" }}
          >
            <div className="">
              <span style={{ color: "#0004FB" }}>Assign To : </span>
              {assignDataForCall.user_name || "N/A"} &nbsp;
              {assignDataForCall.createdAt
                ? moment(assignDataForCall.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : "N/A"}
            </div>

            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "10px" }}
            >
              <div>
                <span style={{ color: "#0004FB" }}>Assign By : </span>
                {assignDataForCall.assign_by_name || "N/A"}
              </div>

              <div className="">
                <Button
                  className="btn btn-primary text-white btn-sm"
                  style={{
                    backgroundColor: "#0004FB",
                    padding: "3px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                    height: "24px",
                  }}
                  onClick={handleViewAllCallHistory}
                >
                  View All
                </Button>
              </div>
            </div>

            {/* View All Button - Centered */}
          </div>
        )}

        <box open={openPopover} onClose={handleClosePopover} arrow="right-top">
          {(contxt?.user?.sections?.[
            type === "jain" ? "ScannerData" : "HinduScannerData"
          ]?.isAdmin ||
            contxt?.user?.sections?.[
              type === "jain" ? "ScannerData" : "HinduScannerData"
            ]?.update) && (
            <Stack spacing={2} sx={{ my: 2 }}>
              <div
                className="px-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {/* Call Section */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Badge
                    badgeContent="0"
                    color="secondary"
                    style={{
                      fontSize: "0.8rem",
                    }}
                    componentsProps={{
                      badge: {
                        style: {
                          borderRadius: "2px",
                        },
                      },
                    }}
                  />
                  <Switch
                    checked={callData?.call_connected_status === 1}
                    onChange={() =>
                      handleCallStatus(newRow?.id, "call_connected")
                    }
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "-15px" }}
                    className="primaryColor fw-500 primaryColor"
                  >
                    Call
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Switch
                    checked={callData?.is_verified === 1}
                    onChange={() => {
                      if (!callData || !callData.id) {
                        enqueueSnackbar("Please connect the call first.", {
                          variant: "error",
                        });
                        return;
                      }
                      handleVerifyStatus(
                        callData.id,
                        callData.is_verified === 0 ? 1 : 0
                      );
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "-15px" }}
                    className="primaryColor fw-500 primaryColor"
                  >
                    Verify
                  </Typography>
                </div>

                {/* NP Section */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Badge
                    badgeContent={0}
                    color="primary"
                    style={{ fontSize: "0.8rem" }}
                  />
                  <Switch
                    color="success"
                    checked={callData?.call_not_picked_status === 1}
                    onChange={() => handleCallStatus(newRow?.id, "not_picked")}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "-15px" }}
                    className="primaryColor fw-500 primaryColor"
                  >
                    NP
                  </Typography>
                </div>

                {/* NC Section */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Badge
                    badgeContent={0}
                    color="primary"
                    style={{ fontSize: "0.8rem" }}
                  />
                  <Switch
                    color="success"
                    checked={callData?.call_not_connected_status === 1}
                    onChange={() =>
                      handleCallStatus(newRow?.id, "not_connected")
                    }
                  />

                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "-15px" }}
                    className="primaryColor fw-500 primaryColor"
                  >
                    NC
                  </Typography>
                </div>
              </div>

              {callData?.call_connected_status === 1 && (
                <div
                  className="d-flex p-1"
                  style={{ fontSize: "14px", backgroundColor: "#f2f2fe" }}
                >
                  <small>
                    <span style={{ color: "#FB0000" }}>Call By</span> -{" "}
                    {callData?.user_name} (
                    {moment(callData?.call_connected_date, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )}{" "}
                    {callData?.call_connected_time
                      ? moment(
                          callData?.call_connected_time,
                          "HH:mm:ss"
                        ).format("hh:mm A")
                      : "N/A"}
                    )
                  </small>
                </div>
              )}
              {callData?.call_connected_status === 1 && (
                <div
                  className="d-flex align-items-center flex-wrap"
                  style={{ gap: "10px" }}
                >
                  <Button
                    size="small"
                    variant={multipleButtons("samband")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "samband",
                        multipleButtons("samband") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Samband
                  </Button>

                  <Button
                    size="small"
                    variant={multipleButtons("share")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "share",
                        multipleButtons("share") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Share
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("later")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "later",
                        multipleButtons("later") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Later
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("special")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "special",
                        multipleButtons("samband") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Special
                  </Button>

                  <Button
                    size="small"
                    variant={multipleButtons("shadi_done")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "shadi_done",
                        multipleButtons("shadi_done") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Shadi Done
                  </Button>

                  <Button
                    size="small"
                    variant={multipleButtons("free_registration")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "free_registration",
                        multipleButtons("free_registration") === "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Free Registration
                  </Button>

                  <Button
                    size="small"
                    variant={multipleButtons("not_interested")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "not_interested",
                        multipleButtons("not_interested") === "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Not Interested
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("hindu")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "hindu",
                        multipleButtons("hindu") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Hindu
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("other_cast")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "other_cast",
                        multipleButtons("other_cast") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    Other Cast
                  </Button>

                  <Button
                    size="small"
                    variant={multipleButtons("on_app")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "on_app",
                        multipleButtons("on_app") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    On App
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("different_languague")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "different_languague",
                        multipleButtons("different_languague") === "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Diff Language
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("can_share_in_group")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "can_share_in_group",
                        multipleButtons("can_share_in_group") === "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Can Share in group
                  </Button>

                  <Button
                    size="small"
                    variant={multipleButtons("interested_to_join_team")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "interested_to_join_team",
                        multipleButtons("interested_to_join_team") ===
                          "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Interested to join team
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("can_become_a_paid_member")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "can_become_a_paid_member",
                        multipleButtons("can_become_a_paid_member") ===
                          "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Can become a paid member
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("vip_customer")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "vip_customer",
                        multipleButtons("vip_customer") === "contained" ? 0 : 1
                      );
                    }}
                  >
                    VIP customer
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("registration_on_app")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "registration_on_app",
                        multipleButtons("registration_on_app") === "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Registration on app
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("no_change_in_biodata")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "no_change_in_biodata",
                        multipleButtons("no_change_in_biodata") === "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    No.change in biodata
                  </Button>
                  <Button
                    size="small"
                    variant={multipleButtons("whatsapp_services_customer")}
                    className="btnPadding"
                    onClick={() => {
                      onCallStatus(
                        newRow.id,
                        "whatsapp_services_customer",
                        multipleButtons("whatsapp_services_customer") ===
                          "contained"
                          ? 0
                          : 1
                      );
                    }}
                  >
                    Whatsapp services customer
                  </Button>
                </div>
              )}

              <div className="d-flex">
                {callData?.is_verified === 1 && (
                  <small>
                    <span style={{ color: "#FB0000" }}> Verified By - </span>
                    {callData?.verified_by} (
                    {callData?.verify_date_time
                      ? moment(callData.verify_date_time).format(
                          "DD/MM/YYYY hh:mm A"
                        )
                      : "N/A"}
                    )
                  </small>
                )}
              </div>
              <div className="d-flex" style={{ color: "#0004FB" }}>
                {notesData?.user_name && (
                  <small>
                    Notes - {notesData?.notes} |
                    <span className="text-dark">
                      {" "}
                      {notesData?.user_name} , (
                      {notesData?.updatedAt
                        ? moment(notesData.updatedAt).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "N/A"}
                      )
                    </span>
                  </small>
                )}
              </div>
              {removeNotesData && removeNotesData?.type !== "suspend" && (
                <div className=" " style={{ fontSize: "14px" }}>
                  <span style={{ color: "#FB0000" }}>Removed By:</span>{" "}
                  {removeNotesData?.remove_by ?? "N/A"} (
                  {moment(removeNotesData?.remove_date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}{" "}
                  {removeNotesData?.remove_time
                    ? moment(removeNotesData?.remove_time, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    : "N/A"}
                  )
                </div>
              )}

              {newRow?.remove_status === 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* Left Side: Removed Reason */}
                  {removeNotesData && removeNotesData?.type !== "suspend" && (
                    <div style={{ flex: 1, fontSize: "14px" }}>
                      <p style={{ margin: 0 }}>
                        <span style={{ color: "#0004FB" }}>
                          {" "}
                          Removed Reason:{" "}
                        </span>{" "}
                        {removeNotesData?.notes}
                      </p>
                    </div>
                  )}
                </div>
              )}

              {removeNotesData && removeNotesData?.type === "suspend" && (
                <div className=" " style={{ fontSize: "14px" }}>
                  <span style={{ color: "#FB0000" }}>Suspended By:</span>{" "}
                  {removeNotesData?.remove_by ?? "N/A"} (
                  {moment(removeNotesData?.remove_date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}{" "}
                  {removeNotesData?.remove_time
                    ? moment(removeNotesData?.remove_time, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    : "N/A"}
                  )
                </div>
              )}

              {newRow?.suspend_status === 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* Left Side: Removed Reason */}
                  {removeNotesData && removeNotesData?.type === "suspend" && (
                    <div style={{ flex: 1, fontSize: "14px" }}>
                      <p style={{ margin: 0 }}>
                        <span style={{ color: "#0004FB" }}>
                          {" "}
                          Suspended Reason:{" "}
                        </span>{" "}
                        {removeNotesData?.notes}
                      </p>
                    </div>
                  )}
                </div>
              )}

              {(contxt?.user?.sections?.ScannerData?.isAdmin ||
                contxt?.user?.sections?.[
                  type === "jain" ? "ScannerData" : "HinduScannerData"
                ]?.approval) &&
                newRow?.update_staff_id && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <b>Approval</b>
                      <Switch
                        color="success"
                        checked={newRow?.approve_status}
                        onChange={handleApproveDisapproveStatus}
                      />
                    </div>
                    {newRow?.approve_status === 1 && (
                      <small>
                        <span style={{ color: "#0004FB" }}>Approved By - </span>
                        {newRow?.approve_by} (
                        {moment(newRow?.approve_date, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {newRow?.approve_time
                          ? moment(newRow?.approve_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )
                          : "N/A"}
                        )
                      </small>
                    )}
                  </div>
                )}

              <div className="d-flex">
                {newRow?.remove_status === 1 && (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ mx: 1 }}
                    onClick={() => {
                      handleRestore(removeNotesData?.id);
                    }}
                  >
                    Restore
                  </Button>
                )}

                {newRow?.suspend_status === 1 && (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ mx: 1 }}
                    onClick={() => {
                      handleRestoreSuspend(removeNotesData?.id);
                    }}
                  >
                    Restore
                  </Button>
                )}

                <Button
                  sx={{ mx: 1 }}
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenNotes(true)}
                >
                  Notes
                </Button>

                {callData?.call_type === "call_connected" &&
                  callData?.call_connected_status === 1 && (
                    <Button
                      variant={
                        callData?.is_done === 1 ? "contained" : "outlined"
                      }
                      sx={{ mx: 1, backgroundColor: "#07C634", color: "#000" }}
                      size="small"
                      color="success"
                      onClick={() => {
                        handleDoneStatus(newRow?.id);
                      }}
                    >
                      Done
                    </Button>
                  )}

                {!newRow?.scan_status ? (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ mx: 1 }}
                    color="primary"
                    onClick={() => {
                      handleScan();
                    }}
                  >
                    Scan
                  </Button>
                ) : null}

                {!Number(newRow?.gpt_status) &&
                Number(newRow?.scan_status) &&
                !isNaN(Number(newRow?.confidence_score)) &&
                Number(newRow?.confidence_score) > 75 ? (
                  <Button
                    variant="contained"
                    color="info"
                    size="small"
                    sx={{ mx: 1 }}
                    onClick={() => {
                      handleGpt();
                    }}
                  >
                    Gpt
                  </Button>
                ) : null}
                {newRow?.gpt_status === 1 && newRow?.final_status !== 1 ? (
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ mx: 1 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleFinal();
                    }}
                  >
                    Final Update
                  </Button>
                ) : null}
              </div>
            </Stack>
          )}
        </box>
      </div>
      {(type === "jain" &&
        newRow?.jain_data_call?.call_connected_status === 1) ||
      (type === "hindu" &&
        newRow?.hindu_data_call?.call_connected_status === 1) ? (
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
          }}
        >
          {/* Sambandh section preview */}
          {newRow?.BioSambandh?.length > 0 && (
            <>
              {" "}
              {newRow?.BioSambandh?.map((val, ind) => {
                return (
                  <div>
                    {val?.type == "0" && (
                      <div className="row align-items-center">
                        <div className="col-10 pe-0">
                          <p className="fs-12 darkText border-bottom px-1 ">
                            <span style={{ color: "#eb445a" }}>
                              <b>SF </b>{" "}
                            </span>
                            {val?.created_for ? ": " + val?.created_for : ""}
                            {val?.name ? "- " + val?.name : ""}
                            {val?.mobile ? "- " + maskNumber(val?.mobile) : ""}
                            {val?.relative_name.trim()
                              ? "- " + val?.relative_name
                              : ""}
                            {val?.age ? "- " + val?.age : ""}
                            {val?.employed_in ? "- " + val?.employed_in : ""}
                            {val?.marital_status
                              ? "- " + val?.marital_status
                              : ""}
                            {val?.sect ? "- " + val?.sect : ""}
                            {val?.state ? "- " + val?.state : ""}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {newRow?.BioSambandh &&
                newRow?.BioSambandh?.map((val, ind) => {
                  return (
                    <div>
                      {val?.type == "1" && (
                        <div className="row align-items-center">
                          <div className="col-10">
                            <p className="fs-12 darkText border-bottom px-1 ">
                              <span style={{ color: "#eb445a" }}>
                                <b>OF </b>{" "}
                              </span>
                              {val?.created_for ? ": " + val?.created_for : ""}
                              {val?.name ? ": " + val?.name : ""}
                              {val?.mobile
                                ? ": " + maskNumber(val?.mobile)
                                : ""}
                              {val?.relative_name
                                ? ": " + val?.relative_name
                                : ""}
                              {val?.employed_in ? "- " + val?.employed_in : ""}
                              {val?.marital_status
                                ? "- " + val?.marital_status
                                : ""}
                              {val?.sect ? "- " + val?.sect : ""}
                              {val?.state ? "- " + val?.state : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </>
          )}
          <div className="row ">
            <div className="col-12">
              {newRow?.partner_preference ? (
                <span className="fs-12 ">
                  <b style={{ color: "#eb445a" }}>Partner Preference : </b>
                  {newRow?.partner_preference}{" "}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              {newRow?.different_language ? (
                <span className="fs-12 ">
                  <b style={{ color: "#eb445a" }}>Different Language : </b>
                  {newRow?.different_language} |{" "}
                  {newRow?.different_language_notes}{" "}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              {newRow?.not_interested_notes ? (
                <span className="fs-12 ">
                  <b style={{ color: "#eb445a" }}>Not Interested Reason : </b>
                  {newRow?.not_interested_notes}{" "}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* notes show */}
          <div className="row">
            {newRow?.other ? (
              <div className="col-12 fs-14" style={{ color: "#525ffe" }}>
                {newRow?.other
                  ? "Notes : " + newRow?.other + newRow?.notes
                  : "" + <br />}
                <span className="fs-12 fw-500" style={{ color: "red" }}>
                  {" "}
                  [
                  {newRow?.notes_date_time
                    ? moment(newRow?.notes_date_time)?.format("DD-MM-YY") +
                      " , " +
                      moment(newRow?.notes_date_time)?.format("HH:mm")
                    : ""}
                  ]
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* notes show */}
          <div className="row ">
            {(newRow?.special || newRow?.special_notes) && (
              <div className="col-12 fs-14">
                <span style={{ color: "#525ffe" }}>
                  {" "}
                  {newRow?.special
                    ? "Special Share : " + newRow?.special
                    : ""}{" "}
                  |{" "}
                </span>
                <span style={{ color: "#EB445A" }}>
                  {newRow?.special_notes ? newRow?.special_notes : ""}
                </span>
              </div>
            )}
          </div>
          {/* notes show */}
          <div className="row">
            {newRow?.normal_share && (
              <div className="col-12">
                {newRow?.normal_share
                  ? "Share : " + newRow?.normal_share
                  : "" + <br />}
              </div>
            )}
          </div>

          {/* followup section */}
          {newRow?.later_status && (
            <div className="d-flex" style={{ gap: "20px" }}>
              <div className="fs-14">
                <span style={{ color: "red" }}>Follow up :</span>{" "}
                {newRow?.later_date_time ? (
                  <span style={{ fontSize: "13px" }}>
                    Date :{" "}
                    {moment(newRow?.later_date_time).format("YYYY-MM-DD")}
                  </span>
                ) : (
                  <span>Date : --- </span>
                )}
              </div>
              <div className="fs-14">
                {newRow?.later_date_time ? (
                  <span style={{ fontSize: "13px" }}>
                    Time : {moment(newRow?.later_date_time).format("HH:mm a")}
                  </span>
                ) : (
                  <span>Time : ---</span>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {/* Dialog */}
      {/* view Image */}
      <Dialog
        Dialog
        open={viewImage?.open}
        onClose={() => {
          handleViewImage("");
        }}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleViewImage("");
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
        />
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                color: "#ed1c24",
                zIndex: "9",
                backgroundColor: "White",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              #{type === "jain" ? "Jain" : "Hindu"}
              {newRow?.id}
            </p>
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
            <div
              id="image-container"
              style={{
                width: "100%",
                height: "100%",
                transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                transformOrigin: "top left",
                cursor: dragging ? "grabbing" : "grab",
                transition: dragging ? "none" : "transform 0.3s ease",
                zIndex: 3,
              }}
              onMouseDown={handleDragStart}
              onMouseMove={handleDrag}
              onMouseUp={handleDragEnd}
              onMouseLeave={handleDragEnd}
              onWheel={handleWheelZoom}
            >
              <Image
                onContextMenu={handleRightClick}
                alt={newRow.value}
                src={viewImage.path}
                sx={{ borderRadius: 1 }}
                style={{
                  width: 500,
                  height: 600,
                  borderRadius: 8,
                  objectFit: "fill",
                }}
                draggable={false}
              />
            </div>
          </div>
          {/* <Button variant="contained" onClick={copyImageWithId}>
            Copy Image with ID
          </Button>*/}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDuplicateImage}
        onClose={() => setOpenDuplicateImage(false)}
      >
        <DialogTitle>View All Duplicate Images</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "16px",
            }}
          >
            {duplicateImages.length > 0 ? (
              duplicateImages.map((image, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <img
                    src={`${constant.scannerUrl}/images/${image.image}`}
                    alt={`Duplicate ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              ))
            ) : (
              <p style={{ gridColumn: "1 / -1", textAlign: "center" }}>
                No duplicate images found.
              </p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDuplicateImage(false)}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={callHistoryModal}
        onClose={() => setCallHistoryModal(false)}
      >
        <DialogTitle>View Call History Details</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Assign To</TableCell>
                  <TableCell>Assign By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {callHistory.map((data, index) => (
                  <TableRow key={data.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="">
                        <span style={{ color: "#0004FB" }}>Assign To : </span>
                        {data.user_name || "N/A"} &nbsp;
                        {data.createdAt
                          ? moment(data.createdAt).format("DD/MM/YYYY hh:mm A")
                          : "N/A"}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="">
                        <span style={{ color: "#0004FB" }}>Assign By : </span>
                        {data.assign_by_name || "N/A"} &nbsp;
                        {data.createdAt
                          ? moment(data.createdAt).format("DD/MM/YYYY hh:mm A")
                          : "N/A"}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCallHistoryModal(false)} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={partnerPreference}
        onClose={() => {
          setPartnerPreference(false);
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogContent sx={{ p: 0, m: 0 }}>
          <Card>
            <CardHeader
              title="Partner Preference"
              sx={{
                backgroundColor: "red",
                color: "white",
                p: 1,
                m: 0,
              }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    sx={{ color: "white" }} // Set the icon color to white
                    className="CloseBtn"
                    onClick={() => {
                      setPartnerPreference(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 1 }}>
                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Age Range
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          select
                          size="small"
                          name="min_age"
                          label="Min Age "
                          value={formData.min_age || ""} // Controlled component
                          onChange={(event) => {
                            setFormData((prev) => ({
                              ...prev,
                              min_age: event.target.value, // Store selected value
                            }));
                          }}
                        >
                          {Array.from({ length: 100 }, (_, i) => (
                            <MenuItem key={i + 18} value={i + 18}>
                              {i + 18}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          select
                          size="small"
                          name="max_age"
                          label="Max Age"
                          value={formData.max_age || ""} // Controlled component
                          onChange={(event) => {
                            setFormData((prev) => ({
                              ...prev,
                              max_age: event.target.value, // Store selected value
                            }));
                          }}
                        >
                          {Array.from({ length: 100 }, (_, i) => (
                            <MenuItem key={i + 18} value={i + 18}>
                              {i + 18}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      State
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      value={formData.state || []} // Ensure always an array
                      onChange={(event, newValue) => {
                        console.log("Selected state:", newValue); // Debugging
                        setFormData((prev) => ({
                          ...prev,
                          state: newValue, // Update formData
                        }));
                      }}
                      options={[
                        "All",
                        ...(filterDetail?.stateList?.map(
                          (opt) => opt?.english
                        ) || []),
                      ]}
                      renderInput={(params) => (
                        <TextField {...params} label="State" name="state" />
                      )}
                    />

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Community
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      value={formData.community || []} // Ensure value is always an array
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({
                          ...prev,
                          community: newValue, // Store selected values
                        }));
                      }}
                      options={[
                        "All",
                        ...(filterDetail?.sects?.map((opt) => opt?.english) ||
                          []),
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Community"
                          name="community"
                        />
                      )}
                    />

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Employed In
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      value={formData.employed_in || []} // Ensure value is always an array
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({
                          ...prev,
                          employed_in: newValue, // Store selected values in formData
                        }));
                      }}
                      options={[
                        "All",
                        ...(filterDetail?.employedIn?.english?.map(
                          (opt) => opt?.label
                        ) || []),
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employed In"
                          name="employed_in"
                        />
                      )}
                    />

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Marital Status
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      value={
                        formData.marital_status?.map((val) =>
                          [
                            { label: "Unmarried", value: "Unmarried" },
                            { label: "Widow/Widower", value: "Widow/Widower" },
                            { label: "Divorced", value: "Divorced" },
                            {
                              label: "Awaiting divorce",
                              value: "Awaiting divorce",
                            },
                          ].find((option) => option.value === val)
                        ) || []
                      }
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({
                          ...prev,
                          marital_status: newValue.map(
                            (option) => option.value
                          ),
                        }));
                      }}
                      options={[
                        { label: "Unmarried", value: "Unmarried" },
                        { label: "Widow/Widower", value: "Widow/Widower" },
                        { label: "Divorced", value: "Divorced" },
                        {
                          label: "Awaiting divorce",
                          value: "Awaiting divorce",
                        },
                      ]}
                      getOptionLabel={(option) => option.value} // Show only value
                      renderInput={(params) => (
                        <TextField {...params} label="Marital Status" />
                      )}
                    />

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Manglik Status
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      value={
                        formData.manglik_status?.map((val) =>
                          [
                            { label: "All", value: "All" },
                            { label: "YES", value: "Yes" },
                            { label: "No", value: "NO" },
                            { label: "Don't Know", value: "DONT KNOW" },
                            { label: "Anshik Mangal", value: "ANSHIK" },
                          ].find((option) => option.value === val)
                        ) || []
                      }
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({
                          ...prev,
                          manglik_status: newValue.map(
                            (option) => option.value
                          ),
                        }));
                      }}
                      options={[
                        { label: "All", value: "All" },
                        { label: "YES", value: "Yes" },
                        { label: "No", value: "NO" },
                        { label: "Don't Know", value: "DONT KNOW" },
                        { label: "Anshik Mangal", value: "ANSHIK" },
                      ]}
                      getOptionLabel={(option) => option.value}
                      renderInput={(params) => (
                        <TextField {...params} label="Manglik Status" />
                      )}
                    />

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Family Status
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      value={
                        formData.family_status?.map((val) =>
                          [
                            {
                              label: "All",
                              value: "All",
                            },
                            {
                              label: "Under Lower class (15k / 1.8 Lakh)",
                              value: "Under Lower class (15k / 1.8 Lakh)",
                            },
                            {
                              label: "Lower Middle Class-15k-25k",
                              value: "Lower Middle Class-15k-25k",
                            },
                            {
                              label: "Middle class-25k-40k",
                              value: "Middle class-25k-40k",
                            },
                            {
                              label: "Upper Middle class-40k-70k",
                              value: "Upper Middle class-40k-70k",
                            },
                            {
                              label: "Rich-70k -1.5 lakh",
                              value: "Rich-70k -1.5 lakh",
                            },
                            {
                              label: "Elite-18 Lakh+",
                              value: "Elite-18 Lakh+",
                            },
                          ].find((option) => option.value === val)
                        ) || []
                      }
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({
                          ...prev,
                          family_status: newValue.map((option) => option.value),
                        }));
                      }}
                      options={[
                        {
                          label: "All",
                          value: "All",
                        },
                        {
                          label: "Under Lower class (15k / 1.8 Lakh)",
                          value: "Under Lower class (15k / 1.8 Lakh)",
                        },
                        {
                          label: "Lower Middle Class-15k-25k",
                          value: "Lower Middle Class-15k-25k",
                        },
                        {
                          label: "Middle class-25k-40k",
                          value: "Middle class-25k-40k",
                        },
                        {
                          label: "Upper Middle class-40k-70k",
                          value: "Upper Middle class-40k-70k",
                        },
                        {
                          label: "Rich-70k -1.5 lakh",
                          value: "Rich-70k -1.5 lakh",
                        },
                        { label: "Elite-18 Lakh+", value: "Elite-18 Lakh+" },
                      ]}
                      getOptionLabel={(option) => option.value}
                      renderInput={(params) => (
                        <TextField {...params} label="Family Status" />
                      )}
                    />

                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Candidate Income
                    </Typography>

                    <Autocomplete
                      multiple
                      size="small"
                      value={formData.income || []}
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({
                          ...prev,
                          income: newValue,
                        }));
                      }}
                      options={[
                        "All",
                        ...(filterDetail?.incomeList?.english?.map(
                          (opt) => opt?.label
                        ) || []),
                      ]}
                      renderInput={(params) => (
                        <TextField {...params} label="Income" name="income" />
                      )}
                    />
                    <Typography variant="h6" style={{ fontSize: "14px" }}>
                      Height
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          select
                          size="small"
                          name="min_height"
                          label="Min Height"
                          value={formData.min_height || ""} // Ensure controlled component
                          onChange={(event) => {
                            setFormData((prev) => ({
                              ...prev,
                              min_height: event.target.value, // Store selected value
                            }));
                          }}
                        >
                          {filterDetail?.height?.english?.map((opt) => (
                            <MenuItem key={opt.label} value={opt.label}>
                              {opt.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          select
                          size="small"
                          name="max_height"
                          label="Max Height"
                          value={formData.max_height || ""} // Ensure controlled component
                          onChange={(event) => {
                            setFormData((prev) => ({
                              ...prev,
                              max_height: event.target.value, // Store selected value
                            }));
                          }}
                        >
                          {filterDetail?.height?.english?.map((opt) => (
                            <MenuItem key={opt.label} value={opt.label}>
                              {opt.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                  <Button
                    onClick={() => handleSubmit(newRow?.id)}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openNotes}
        onClose={() => setOpenNotes(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {/* Title and Close Button */}
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Grid item>
              <Typography variant="h6">Add Notes</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Close">
                <IconButton onClick={() => setOpenNotes(false)}>
                  <Iconify icon="mdi:cancel-circle-outline" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <FormGroup>
            <Grid container spacing={1}>
              {noteOptions?.notes?.map(({ id, title }) => (
                <Grid item xs={6} key={id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedNotes.some((note) => note.id === id)}
                        onChange={() => handleCheckboxChange({ id, title })}
                      />
                    }
                    label={title}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>

          <TextField
            fullWidth
            label="Note"
            multiline
            name="notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
              if (errors.notes) setErrors({ ...errors, notes: "" }); // Clear error on user input
            }}
            sx={{ mt: 2 }}
            error={!!errors.notes}
            helperText={errors.notes}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ my: 2 }}
            onClick={() => handleAddNotes(newRow?.id)}
          >
            Add Notes
          </Button>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        className="deleteModal"
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete Biodata"
        content="Are you sure to delete this biodata ?"
        action={
          <>
            <Button
              variant="contained"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </Button>
          </>
        }
      />
    </div>
  );
}
