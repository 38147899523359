import { ContentCopyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import moment from "moment";

const ExcelDataCard = ({ data, onSelect, isSelected }) => {
  return (
    <Card>
      <Stack direction="row" sx={{ background: "#32BE8F", color: "#fff" }}>
        <Grid sx={{ p: 1 }} container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Stack direction={"column"} spacing={1}>
              <p style={{ margin: 0, fontSize: "12px", fontWeight: "bold" }}>
                #{data?.id}
              </p>
              <p style={{ margin: 0, fontSize: "12px", fontWeight: "bold" }}>
                {data?.candidate_name}
              </p>
              <p style={{ margin: 0, fontSize: "12px", fontWeight: "bold" }}>
                {data?.excel_upload_data?.name || data?.excel_jain_upload_data?.name || data?.excel_hindu_upload_data?.name || data?.excel_other_upload_data?.name}
              </p>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Stack direction={"column"} spacing={1}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant={isSelected ? "contained" : "cleared"}
                  onClick={onSelect}
                  sx={{
                    height: "20px",
                    border: "1px solid #fff",
                    color: "#fff",
                    fontSize: "12px",
                    width: "30px",
                    margin: "0 5px",
                  }}
                >
                  {isSelected ? "unselect" : "Select"}
                </Button>
                <Button
                  variant="cleared"
                  sx={{
                    height: "20px",
                    border: "1px solid #fff",
                    color: "#fff",
                    fontSize: "12px",
                    width: "30px",
                    margin: "0 5px",
                  }}
                >
                  Edit
                </Button>
              </div>
              <div>
                <Button
                  variant="cleared"
                  sx={{
                    height: "20px",
                    border: "1px solid #fff",
                    color: "#fff",
                    fontSize: "12px",
                    float: "right",
                  }}
                >
                  View Field
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" sx={{ background: "#32BE8F", color: "#fff" }}>
        <Grid container>
          <Grid
            sx={{
              backgroundColor: "#FFFACD",
              color: "#000",
              width: "100%",
              margin: 0,
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
            item
            md={12}
            sm={12}
            xs={12}
            lg={12}
          >
            <p style={{ margin: 1, fontSize: "10px", fontWeight: "bold" }}>
              Excel Data -{" "}
              {[
                data?.gender,
                data?.community,
                data?.profession,
                data?.height,
                data?.country,
                data?.city && `${data?.city} (${data?.state})`,
                data?.age,
                data?.profession,
                data?.education,
                data?.family_status,
                data?.income,
                data?.pin_code,
                data?.locality,
                data?.sub_locality,
                data?.address,
                data?.building_name,
                data?.landmark,
                data?.std_code,
                data?.telephone_1,
                data?.telephone_2,
                data?.telephone_3,
                // data?.mobile_number_1,
                // data?.mobile_number_2,
                // data?.mobile_number_3,
                data?.toll_free_number,
                data?.candidate_name,
                data?.gmail_1,
                data?.gmail_2,
                data?.company_name,
                data?.website,
                data?.medical_field_1,
                data?.medical_field_2,
                data?.medical_field_3,
                data?.business_name,
              ]
                .filter(Boolean)
                .join(" | ")}
            </p>
          </Grid>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "12px", ml: 1 }}
            >
              Available in :- Biodata | Online User
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "5px 0",
              borderTop: "1px solid #fff",
            }}
            md={12}
            sm={12}
            xs={12}
            lg={12}
          >
            <Typography
              variant="body1"
              sx={{
                ml: 1,
                fontSize: "12px",
              }}
            >
              Data Type-{" "}
            </Typography>
            <div
              style={{
                border: "1px solid #fff",
                color: "#fff",
                borderRadius: "5px",
                padding: "1px 2px",
                margin: "0 5px",
              }}
            >
              {data?.cast}:{data?.community}:{data?.sub_community}{" "}
            </div>
            <div
              style={{
                border: "1px solid #fff",
                color: "#fff",
                borderRadius: "5px",
                padding: "1px 2px",
                margin: "0 5px",
              }}
            >
              {data?.family_status}{" "}
            </div>
          </Grid>
        </Grid>
      </Stack>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            {data?.mobile_number_1 && (
              <Stack
                direction="row"
                justifyContent={"space-between"}
                my={"5px"}
                spacing={1}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid #FF0000",
                    color: "#FF0000",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2px 5px",
                  }}
                >
                  {data?.mobile_number_1}
                  <ContentCopyOutlined
                    sx={{ width: "15px", height: "15px", ml: "5px" }}
                  />
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #4000FF",
                      color: "#4000FF",
                      fontSize: "12px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 5px",
                    }}
                  >
                    WA
                  </div>
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #5E8E6E",
                      color: "#3fae26",
                      fontSize: "10px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 5px",
                    }}
                  >
                    <WhatsAppIcon />
                  </div>
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #005DFF",
                      color: "#005DFF",
                      fontSize: "10px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 5px",
                    }}
                  >
                    <PhoneIcon />
                  </div>
                </Box>
              </Stack>
            )}
            {data?.mobile_number_2 && (
              <Stack
                direction="row"
                justifyContent={"space-between"}
                my={"5px"}
                spacing={1}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    border: "1px solid #FF0000",
                    color: "#FF0000",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2px 5px",
                  }}
                >
                  {data?.mobile_number_2}
                  <ContentCopyOutlined
                    sx={{ width: "15px", height: "15px", ml: "5px" }}
                  />
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #4000FF",
                      color: "#4000FF",
                      fontSize: "12px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 5px",
                    }}
                  >
                    WA
                  </div>
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #5E8E6E",
                      color: "#3fae26",
                      fontSize: "10px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 5px",
                    }}
                  >
                    <WhatsAppIcon />
                  </div>
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #005DFF",
                      color: "#005DFF",
                      fontSize: "10px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 5px",
                    }}
                  >
                    <PhoneIcon />
                  </div>
                </Box>
              </Stack>
            )}
          </Grid>

          <Grid item md={12} sm={12} xs={12} lg={12}>
            <Stack direction={"column"} spacing={2}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                <span style={{ color: "#FF0000" }}>Moved By:</span> Pooja Sen
                <span style={{ color: "#0051FF" }}>(18/02/2025, 10:10PM)</span>
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                <span style={{ color: "#FF0000" }}>Uploaded By: </span> 
                {data?.excel_upload_data?.user_name}{" "}
                <span style={{ color: "#0051FF" }}>
                  (
                  {data?.excel_upload_data?.createdAt
                    ? moment(data?.excel_upload_data.createdAt).format(
                        "DD/MM/YYYY hh:mm A"
                      )
                    : "N/A"}
                  )
                </span>
              </Typography>
            </Stack>
          </Grid>

          <Grid item md={12} sm={12} xs={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  background: "#080CFF",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  mx: 1,
                  width: "100px",
                  color: "#fff",
                  "&:hover": {
                    background: "#080CFF",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  },
                }}
              >
                Note
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#1FC18E",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  color: "#fff",
                  mx: 1,
                  width: "100px",
                  "&:hover": {
                    backgroundColor: "#1FC18E",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  },
                }}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExcelDataCard;
