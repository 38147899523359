import { DeleteOutline, SearchRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  Tooltip,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Iconify from "../../components/iconify";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";

import { useAuthContext } from "src/auth/useAuthContext";
import Label from "src/components/label";
import constant from "src/Constant";
import ScannerBioDataUserCard from "src/sections/@dashboard/user/cards/ScannerBiodataUserCard";
import {
  getCityListAPI,
  getCountryListAPI,
  getDetailList,
  getHinduSectsByStateIdApi,
  getHinduSubSectsBySectIdApi,
  getStateListAPI,
  mobileCheckPy,
  selfAssignScannerData,
  subSectApi,
  updateScannerForm,
  loadWFHData,
  removeScannerTask,
  moveToData,
  staffAssignTaskReportData,
  addOrganizationPy,
  suspendedScannerTask,
  searchCityApi,
  searchHinduSubSectsByQuery,
  searchSubSectByQuery,
} from "src/service";
import FullScreenOptions from "src/components/settings/drawer/FullScreenOptions";

const disability = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Some Medical Problem" },
  { id: 3, name: "Physically Challenged (Handicapped)" },
  { id: 4, name: "Surajmukhi" },
];

const countryCodes = [
  { code: "+1", country: "USA" },
  { code: "+44", country: "UK" },
  { code: "+91", country: "India" },
  { code: "+61", country: "Australia" },
  { code: "+81", country: "Japan" },
  { code: "+49", country: "Germany" },
  { code: "+33", country: "France" },
  { code: "+39", country: "Italy" },
  { code: "+86", country: "China" },
  { code: "+7", country: "Russia" },
  { code: "+55", country: "Brazil" },
  { code: "+52", country: "Mexico" },
  { code: "+27", country: "South Africa" },
  { code: "+82", country: "South Korea" },
  { code: "+34", country: "Spain" },
  { code: "+62", country: "Indonesia" },
  { code: "+66", country: "Thailand" },
  { code: "+31", country: "Netherlands" },
  { code: "+20", country: "Egypt" },
  { code: "+63", country: "Philippines" },
  { code: "+92", country: "Pakistan" },
  { code: "+234", country: "Nigeria" },
  { code: "+46", country: "Sweden" },
  { code: "+45", country: "Denmark" },
  { code: "+41", country: "Switzerland" },
  { code: "+43", country: "Austria" },
  { code: "+65", country: "Singapore" },
  { code: "+94", country: "Sri Lanka" },
  { code: "+98", country: "Iran" },
  { code: "+60", country: "Malaysia" },
  { code: "+254", country: "Kenya" },
  { code: "+351", country: "Portugal" },
  { code: "+356", country: "Malta" },
  { code: "+64", country: "New Zealand" },
  { code: "+32", country: "Belgium" },
  { code: "+968", country: "Oman" },
  { code: "+973", country: "Bahrain" },
  { code: "+974", country: "Qatar" },
  { code: "+966", country: "Saudi Arabia" },
  { code: "+971", country: "UAE" },
  { code: "+973", country: "Bahrain" },
  { code: "+90", country: "Turkey" },
  { code: "+880", country: "Bangladesh" },
];

const casteCategory = [
  { id: 1, name: "General" },
  { id: 2, name: "OBC" },
  { id: 3, name: "SC" },
  { id: 4, name: "ST" },
];

const timingOption = [
  { label: "All", value: "all" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Before Yesterday", value: "beforeYesterday" },
  { label: "Last 3 Days", value: "lastThreeDays" },
  { label: "Last Week", value: "lastWeek" },
  { label: "Last Month", value: "lastMonth" },
  { label: "Last Year", value: "lastYear" },
];

const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

const jainFamilyStatus = [
  { label: "Don't Know", value: "Don't Know" },
  { label: "Not in biodata", value: "Not in biodata" },
  {
    label: "Under Lower class (15k / 1.8 Lakh)",
    value: "Under Lower class (15k / 1.8 Lakh)",
  },
  { label: "Lower Middle Class-15k-25k", value: "Lower Middle Class-15k-25k" },
  { label: "Middle class-25k-40k", value: "Middle class-25k-40k" },
  { label: "Upper Middle class-40k-70k", value: "Upper Middle class-40k-70k" },
  { label: "Rich-70k -1.5 lakh", value: "Rich-70k -1.5 lakh" },
  { label: "Elite-18 Lakh+", value: "Elite-18 Lakh+" },
];

const hinduFamilyStatus = [
  { label: "Don't Know", value: "Don't Know" },
  { label: "Not in biodata", value: "Not in biodata" },
  { label: "Under Lower class-12k", value: "Under Lower class-12k" },
  { label: "Lower Middle Class-12k-20k", value: "Lower Middle Class-12k-20k" },
  { label: "Middle class-20k-30k", value: "Middle class-20k-30k" },
  { label: "Upper Middle class-30k-50k", value: "Upper Middle class-30k-50k" },
  { label: "Rich-50k+", value: "Rich-50k+" },
  { label: "Elite-1 Lakh+", value: "Elite-1 Lakh+" },
];

const ViewWorkFromHomeStaffTask = () => {
  const { type, role } = useParams();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [filterDetail, setFilterDetail] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");
  const [suspendedReason, setSuspendedReason] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [sourceTable, setSourceTable] = useState("jain");
  const [mobileNumber, setMobileNumber] = useState("");
  const [metriMobileNumber, setMetriMobileNumber] = useState("");
  const [organizationName, setOrganizationName] = useState("");

  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const [image, setImage] = useState({});
  const [todayUpdateReport, setTodayUpdateReport] = useState({});

  const [currentId, setCurrentId] = useState(0);
  const [pendingTask, setPendingTask] = useState(0);
  const [completedTask, setCompletedTask] = useState(0);
  const [totalAssignedTask, setTotalAssignedTask] = useState(0);
  const [mobileNumbers, setMobileNumbers] = useState([""]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSubSect, setSearchSubSect] = useState("");
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prefoundData, setPrefoundData] = useState({
    jain_data: [],
    hindu_data: [],
  });
  const [prefoundDataCount, setPrefoundDataCount] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [subSectList, setSubSectList] = useState([]);
  const [isNumberChecking, setIsNumberChecking] = useState(false);
  const [count, setCount] = useState({});
  const [reportPopup, setReportPopup] = useState(false);
  const [reasonType, setReasonType] = useState("no_mobile_number");
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: 80 - 18 + 1 },
    (_, i) => currentYear - (18 + i)
  );
  const ageOptions = Array.from({ length: 38 }, (_, i) => 18 + i);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    gender: "male",
    profession: "",
    education: [],
    community: "",
    height: "",
    motherTongue: "",
    income: "",
    sub_community: "",
    manglik: "DONT KNOW",
    country: "India",
    state: "Madhya Pradesh",
    city: "",
    division: "",
    maritalStatus: "Unmarried",
    physicalStatus: "Normal",
    dob: "",
    familyStatus: "",
    casteCategory: "",
    age: "",
    gmail_id: "",
  });

  const handleAddModal = () => {
    setOpenAddModal(!openAddModal);
  };

  const handleOrganizationSubmit = async () => {
    if (!organizationName || !mobileNumber || !countryCode) {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
      return;
    }

    try {
      const res = await addOrganizationPy({
        organization_name: organizationName,
        mobile: mobileNumber,
        country_code: countryCode,
        source_table: sourceTable,
        user_id: user?.id,
        user_name: user?.nameEnglish,
        user_email: user?.email,
      });

      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
        setOpenAddModal(false);
        setOrganizationName("");
        setMobileNumber("");
        setCountryCode("");
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleFullscreenChange = (status) => {
    setIsFullscreen(status);
  };

  const handleGenderChange = (gender) => {
    setFormData({
      ...formData,
      gender: gender,
    });
  };
  const handleReasonType = (type) => {
    setReasonType(type);
  };

  const scannerDataKey =
    role === "office" && type === "jain"
      ? "biodataDownloadUpdate"
      : role === "office" && type === "hindu"
      ? "hinduBiodataDownloadUpdate"
      : "ScannerData";

  const initialFilter = {
    role:
      role === "office"
        ? user?.sections?.[scannerDataKey]?.isAdmin
          ? "admin"
          : "staff"
        : user?.sections?.[scannerDataKey]?.WorkFromHomeStaff
        ? "WorkFromHomeStaff"
        : user?.sections?.[scannerDataKey]?.isAdmin
        ? "admin"
        : "staff",
    user_id: user?.id,
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleWheelZoom = useCallback(
    (event) => {
      if (event.shiftKey) {
        // Use Shift key as the modifier
        event.preventDefault();
        const zoomFactor = 0.1; // Adjust the zoom sensitivity

        setZoom((prevZoom) => {
          let newZoom;
          if (event.deltaY < 0) {
            newZoom = Math.min(prevZoom + zoomFactor, 3); // Zoom in
          } else {
            newZoom = Math.max(prevZoom - zoomFactor, 1); // Zoom out
            if (newZoom === 1) setPosition({ x: 0, y: 0 });
          }
          return newZoom;
        });
      }
    },
    [setZoom, setPosition]
  );

  const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level

  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.1, 1); // Ensures zoom doesn't go below 1
      if (newZoom === 1) setPosition({ x: 0, y: 0 });
      return newZoom;
    });
  };

  const handleDragStart = (e) => {
    if (zoom <= 1) return;
    setDragging(true);
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  useEffect(() => {
    const handleDrag = (e) => {
      if (!dragging || zoom <= 1) return;

      const newX = e.clientX - startPosition.x;
      const newY = e.clientY - startPosition.y;

      const maxOffsetX = ((zoom - 1) * window.innerWidth) / 2;
      const maxOffsetY = ((zoom - 1) * window.innerHeight) / 2;

      setPosition({
        x: Math.min(Math.max(newX, -maxOffsetX), maxOffsetX),
        y: Math.min(Math.max(newY, -maxOffsetY), maxOffsetY),
      });
    };

    const handleDragEnd = () => setDragging(false);

    if (dragging) {
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging, zoom, startPosition]);

  const handleDrag = (e) => {
    if (!dragging || zoom <= 1) return;

    const newX = e.clientX - startPosition.x;
    const newY = e.clientY - startPosition.y;

    const maxOffsetX = ((zoom - 1) * window.innerWidth) / 2;
    const maxOffsetY = ((zoom - 1) * window.innerHeight) / 2;

    setPosition({
      x: Math.min(Math.max(newX, -maxOffsetX), maxOffsetX),
      y: Math.min(Math.max(newY, -maxOffsetY), maxOffsetY),
    });
  };

  const handleDragEnd = () => setDragging(false);

  const getWorkFromHomeStaffTask = async () => {
    let obj = {
      user_id: user?.id,
      user_name: user?.nameEnglish,
      user_email: user?.email,
      assign_by: "Self",
      assign_quantity: 100,
    };
    try {
      const response = await selfAssignScannerData(type, obj);
      if (response?.status === 200) {
        loadData();
      } else {
        enqueueSnackbar(response?.data?.message || "Something went wrong!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
      enqueueSnackbar(
        "You have incomplete tasks. Please complete them before new assignments.",
        {
          variant: "error",
        }
      );
    }
  };

  const loadData = async () => {
    const obj = {
      user_id: user?.id,
      user_name: user?.nameEnglish,
      user_email: user?.email,
    };

    try {
      const response = await loadWFHData(type, obj);

      const {
        onTask,
        completed_task,
        metrimonyNumbers,
        pending_task,
        today_update_report,
        total_assign_task,
      } = response?.data?.data;

      setMetriMobileNumber(metrimonyNumbers?.all_mobile);
      setCompletedTask(completed_task);
      setPendingTask(pending_task);
      setTodayUpdateReport(today_update_report);
      setTotalAssignedTask(total_assign_task);

      if (onTask) {
        setMobileNumbers(onTask?.mobiles ? onTask?.mobiles?.split(",") : []);
        setCurrentId(onTask?.id);
        setImage(type === "jain" ? onTask.jain_image : onTask.hindu_image);
        setFormData({
          id: onTask?.id,
          name: onTask?.name,
          gender: onTask?.gender,
          profession: onTask?.employed_in,
          education: onTask?.education?.split(","),
          sect: onTask?.community,
          subSect: onTask?.sub_community,
          manglik: onTask?.manglik ? onTask?.manglik : "",
          country: onTask?.country ? onTask?.country : "",
          state: onTask?.state ? onTask?.state : "",
          city: onTask?.city,
          maritalStatus: onTask?.marital_status ? onTask?.marital_status : "",
          physicalStatus: onTask?.physical_status
            ? onTask?.physical_status
            : "",
          familyStatus: onTask?.familyStatus ? onTask?.familyStatus : "",
          casteCategory: onTask?.casteCategory ? onTask?.casteCategory : "",
          dob: onTask?.dob === "-" ? 0 : onTask?.dob,
          height: onTask?.height,
          motherTongue: onTask?.mother_tongue,
          income: onTask?.income,
          description: onTask?.description,
          age: onTask?.age,
          gmail_id: onTask?.gmail_id,
        });
      } else {
        setFormData({
          id: "",
          name: "",
          gender: "male",
          profession: "",
          education: [],
          community: "",
          height: "",
          motherTongue: "",
          income: "",
          sub_community: "",
          manglik: "",
          country: "",
          state: "",
          city: "",
          maritalStatus: "",
          physicalStatus: "",
          dob: "",
          familyStatus: "",
          casteCategory: "",
          description: "",
          age: "",
          gmail_id: "",
        });
      }

      // Handle string response as an error
      if (typeof response?.data?.data === "string") {
        enqueueSnackbar(response?.data?.data, { variant: "error" });
        return;
      }

      // Handle successful response here (if needed)
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
    }
  };

  const fetchStaffAssignTaskReport = async () => {
    try {
      const obj = {
        staff_id: user?.id,
        timing: filter?.staff_filter_timing,
        source_table: type == "jain" ? "jain_data" : "hindu_data",
        report_type: "update",
      };
      const response = await staffAssignTaskReportData(obj);
      // console.log(response);
      if (response?.status === 200) {
        const responseData = response?.data?.data[0] || [];
        setCount(responseData);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const getCountryList = useCallback(async () => {
    let res = await getCountryListAPI();
    setCountryList(res?.data?.data);
  }, []);

  useEffect(() => {
    getCountryList();
  }, [getCountryList]);

  const getStateList2 = useCallback(async () => {
    var filterDetails = await getDetailList();
    let stateList = await getStateListAPI("India");
    setFilterDetail({
      ...filterDetails?.data?.data,
      stateList: stateList?.data.data,
      sects: type === "hindu" ? [] : filterDetails?.data?.data?.sects,
    });
  }, [type]);

  useEffect(() => {
    getStateList2();
  }, [getStateList2]);

  const handleReport = async () => {
    setReportPopup(true);
  };

  const getCityList = async (val) => {
    let res = await getCityListAPI(val);
    setCityList(res?.data?.data);
  };

  const getHinduCommunityList = async (val) => {
    const stateId =
      filterDetail?.stateList?.find((item) => item.english === val)?.id || null;
    if (!stateId) return;
    let res = await getHinduSectsByStateIdApi(stateId);
    let sect = [];
    res?.data?.data.forEach((item) => {
      sect.push({
        id: item.id,
        english: item.name_english,
        hindi: item.name_english,
        master_status: true,
      });
    });
    setFilterDetail({ ...filterDetail, sects: sect });
  };

  const getHinduSubCommunity = async (sectId) => {
    const sect =
      filterDetail?.sects?.find((item) => item.english === sectId)?.id || null;
    const res = await getHinduSubSectsBySectIdApi(sect);
    let subCommunity = [];
    res?.data?.data?.forEach((item) => {
      subCommunity.push({
        id: item.id,
        english: item.name_english,
        hindi: item.name_english,
      });
    });
    setSubSectList(subCommunity);
  };

  const getSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubSectList(res?.data?.data);
    }
  };

  useEffect(() => {
    if (formData?.community) {
      if (type === "hindu") getHinduSubCommunity(formData?.community);
      if (type === "jain") getSubSect(formData?.community);
    }
  }, [formData?.community]);

  useEffect(() => {
    if (formData.state) {
      getCityList(formData.state);
      if (type === "hindu") getHinduCommunityList(formData.state);
    }
  }, [formData.state, type]);

  const checkMobileNumber = async (number) => {
    setIsNumberChecking(true);
    try {
      const res = await mobileCheckPy(number);
      if (res?.data?.status) {
        setPrefoundData(res?.data?.data?.result);
        const count =
          res?.data?.data?.result?.jain_data?.length +
          res?.data?.data?.result?.hindu_data?.length;
        setPrefoundDataCount(count);
      }
    } catch (error) {
      console.error("error ::::", error);
    } finally {
      setIsNumberChecking(false);
    }
  };

  useEffect(() => {
    if (
      mobileNumbers.length > 0 &&
      mobileNumbers[0] &&
      mobileNumbers[0] !== "-"
    ) {
      const timer = setTimeout(() => {
        checkMobileNumber(mobileNumbers[0]);
      }, 1200);

      return () => clearTimeout(timer);
    }
  }, [mobileNumbers?.length, mobileNumbers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
    let updatedErrors = { ...errors, [name]: "" };

    if (name === "dob" && value) {
      // Calculate age based on selected year
      const selectedYear = parseInt(value);
      const calculatedAge = currentYear - selectedYear;
      updatedFormData.age = calculatedAge;
    }

    if (name === "age" && value) {
      // Calculate birth year based on selected age
      const selectedAge = parseInt(value);
      const calculatedYear = currentYear - selectedAge;
      updatedFormData.dob = calculatedYear;
    }

    if (name === "city" && value) {
      let selectedCity = cityList.find((i) => i.name_english === value);
      console.log(cityList);
      // console.log(selectedCity?.id);
    }

    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };

  const handleEducation = (event, value) => {
    setFormData((prev) => ({
      ...prev,
      education: value,
    }));
    setErrors((prev) => ({
      ...prev,
      education: "", // Clear the error for education
    }));
  };

  const addMobileField = () => {
    setMobileNumbers([...mobileNumbers, ""]);
  };

  const handleChange = (index, value) => {
    const updatedNumbers = [...mobileNumbers];
    updatedNumbers[index] = value;
    setMobileNumbers(updatedNumbers);
  };

  const removeMobileField = (index) => {
    const updatedNumbers = mobileNumbers.filter((_, i) => i !== index);
    setMobileNumbers(updatedNumbers);
  };

  const handleSubmit = async (submit_type) => {
    try {
      const formObj = {
        id: formData?.id,
        name: formData?.name,
        gender: formData?.gender,
        dob: formData?.dob,
        education: formData?.education?.join(","),
        marital_status: formData?.maritalStatus,
        physical_status: formData?.physicalStatus,
        family_status: formData?.familyStatus,
        caste_category: formData?.casteCategory,
        community: formData?.community,
        sub_community: formData?.sub_community,
        manglik: formData?.manglik,
        country: formData?.country,
        state: formData?.state,
        city: formData?.city,
        height: formData?.height,
        mother_tongue: formData?.motherTongue,
        income: formData?.income,
        employed_in: formData?.profession,
        mobiles: mobileNumbers?.join(","),
        update_staff_id: user?.id,
        update_staff_name: user?.nameEnglish,
        update_staff_email: user?.email,
        submit_type: submit_type,
        age: formData?.age,
        gmail_id: formData?.gmail_id,
        section: "work_from_home",
      };

      const newErrors = {};
      if (!formObj.name?.trim()) {
        newErrors.name = "Name is required";
      }

      if (!formObj.education?.trim()) {
        newErrors.education = "Education is required";
      }
      if (!formObj.state?.trim()) {
        newErrors.state = "State is required";
      }
      if (!formObj.country?.trim()) {
        newErrors.country = "Country is required";
      }
      if (!formObj.city?.trim()) {
        newErrors.city = "City is required";
      }
      if (!formObj.community?.trim()) {
        newErrors.community = "Community is required";
      }
      if (!formObj.gender?.trim()) {
        newErrors.gender = "Gender is required";
      }
      if (!formObj.marital_status?.trim()) {
        newErrors.maritalStatus = "Marital Status is required";
      }

      if (!formObj.employed_in?.trim()) {
        newErrors.profession = "Profession is required";
      }
      if (!formObj.mobiles?.trim()) {
        newErrors.mobileNumbers = "Mobile Number is required";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
      const response = await updateScannerForm(formObj, type);

      if (response?.status === 200) {
        loadData();
        setFormData({
          id: "",
          name: "",
          gender: "male",
          profession: "",
          education: [],
          community: "",
          height: "",
          motherTongue: "",
          income: "",
          sub_community: "",
          manglik: "DONT KNOW",
          country: "India",
          state: "Madhya Pradesh",
          city: "",
          maritalStatus: "Unmarried",
          physicalStatus: "Normal",
          dob: "",
          familyStatus: "",
          casteCategory: "",
        });
        enqueueSnackbar("Biodata Updated Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleMoveToData = async (submit_type) => {
    try {
      const formObj = {
        id: [formData?.id],
        user_id: user?.id,
        submit_type: submit_type,
        section: "work_from_home",
      };

      const response = await moveToData(type, formObj);
      if (response?.status === 200) {
        loadData();
        enqueueSnackbar("Moved Data Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRemove = async () => {
    const reasonText = reason ? reason.trim() : "";

    const newErrors = {};

    if (!reasonText) {
      newErrors.reason = "Reason field is required";
      setErrors(newErrors);
      return; // Stop execution if there's a validation error
    }

    try {
      setErrors({});
      const formObj = {
        id: formData?.id,
        user_id: user?.id,
        user_name: user?.nameEnglish,
        notes: reason,
        reason_type: reasonType,
        status: 1,
        section: "work_from_home",
      };
      const response = await removeScannerTask(formObj, type);
      if (response?.status === 200) {
        loadData();
        setOpenModal(false);
        setReason("");
        enqueueSnackbar("Removed Task Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  const handleSuspendedTask = async () => {
    const reasonText = suspendedReason ? suspendedReason.trim() : "";

    const newErrors = {};

    if (!reasonText) {
      newErrors.suspendedReason = "Reason field is required";
      setErrors(newErrors);
      return;
    }
    try {
      setErrors({});
      const formObj = {
        id: formData?.id,
        user_id: user?.id,
        notes: suspendedReason,
        user_name: user?.nameEnglish,
        status: 1,
        section: "work_from_home",
      };
      const response = await suspendedScannerTask(formObj, type);
      if (response?.status === 200) {
        loadData();
        setOpenSuspendModal(false);
        setSuspendedReason("");
        enqueueSnackbar("Suspended Task Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  // const handleWheelZoom = (event) => {
  //   // event.preventDefault();
  //   const zoomFactor = 0.1;

  //   setZoom((prevZoom) => {
  //     const newZoom = Math.max(
  //       1,
  //       Math.min(3, prevZoom - event.deltaY * zoomFactor * 0.01)
  //     );

  //     if (newZoom === 1) {
  //       setPosition({ x: 0, y: 0 });
  //     }

  //     return newZoom;
  //   });
  // };

  useEffect(() => {
    if (!searchQuery) {
      setCityList([]);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      try {
        const response = await searchCityApi(searchQuery);

        if (response?.data?.status) {
          setFormData({
            ...formData,
            country: response?.data?.data?.country
              ? response?.data?.data?.country?.name_english
              : "",
            state: response?.data?.data?.state
              ? response?.data?.data?.state?.name_english
              : "",
            city: response?.data?.data?.foundCity
              ? response?.data?.data?.foundCity?.name_english
              : "",
            division: response?.data?.data?.division?.name_english
              ? response?.data?.data?.division?.name_english
              : "",
          });
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (!searchSubSect && type === "hindu") {
      setSubSectList([]);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      try {
        const response = await searchSubSectByQuery(searchSubSect);
        const subSectRes = response?.data?.data?.english;
        const sectRes = response?.data?.data?.sect;
        if (response?.data?.status) {
          if (type === "jain") {
            console.log("sectRes ::::", sectRes);
            setFormData((prev) => ({
              ...prev,
              community: sectRes || prev.community,
              sub_community: subSectRes || prev.sub_community,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchSubSect]);

  return (
    <>
      <Helmet>
        <title>{type === "jain" ? "JAIN" : "HINDU"} : Biodata</title>
      </Helmet>
      <Box sx={{ padding: "0px 20px", maxHeight: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Heading */}
          <Typography variant="h4">
            {type === "jain" ? "Jain" : "Hindu"} Biodata Details Updating
          </Typography>

          {/* View Report Button */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <Button variant="contained" sx={{ mr: 1, my: 2 }}>
              Pending ({pendingTask})
            </Button>

            <Button variant="contained" sx={{ mr: 1, my: 2 }} color="success">
              Completed Task ({completedTask})
            </Button>

            {/* <Button variant="contained" sx={{ mr: 1, my: 2 }} >
          Approved Task ({todayUpdateReport?.approve_task})
        </Button>

        <Button variant="outlined" sx={{ mr: 1, my: 2 }} >
          Remove Task ({todayUpdateReport?.remove_task})
        </Button>

        {type == "jain" ? (
          <Button variant="contained" sx={{ mr: 1, my: 2 }} >
            Move To Hindu ({todayUpdateReport?.move_to_hindu})
          </Button>
        ) : (
          <Button variant="contained" sx={{ mr: 1, my: 2 }} >
            Move To Jain ({todayUpdateReport?.move_to_jain})
          </Button>
        )} */}

            <Button variant="outlined" sx={{ mr: 1, my: 2 }} color="success">
              Id ({currentId})
            </Button>

            <Button
              variant="contained"
              sx={{ mr: 1, my: 2 }}
              color="success"
              onClick={getWorkFromHomeStaffTask}
            >
              Assign Task ({totalAssignedTask})
            </Button>
            <div>
              <IconButton onClick={handleZoomIn} aria-label="Zoom In">
                <ZoomInIcon sx={{ fontSize: "35px", mt: "4px" }} />
              </IconButton>
              <IconButton onClick={handleZoomOut} aria-label="Zoom Out">
                <ZoomOutIcon sx={{ fontSize: "35px", mt: "4px" }} />
              </IconButton>
            </div>
            <div>
              <FullScreenOptions
                style={{
                  fontSize: "10px",
                  padding: "10px",
                  height: "10px !important",
                }}
                onFullscreenChange={handleFullscreenChange}
              />
            </div>
          </Box>
          <Stack sx={{ float: "right" }} direction="row" spacing={2}>
            <Button variant="contained" size="small" onClick={handleReport}>
              View Report
            </Button>
            <Button
              variant="contained"
              sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
              onClick={handleAddModal}
            >
              Add Matrimony
            </Button>
          </Stack>
        </Box>

        {formData?.id && (
          <Grid container spacing={2}>
            {/* image */}
            <Grid item md={5} lg={7} xs={12} padding={2}>
              <Stack
                spacing={2}
                direction="column"
                sx={{
                  position: "relative",
                  width: "100%",
                  // height: "75vh",
                  height: isFullscreen ? "85vh" : "76vh", // Random test heights
                  overflow: "hidden",
                  border: "2px solid #ccc",
                  zIndex: 2,
                }}
              >
                <div
                  id="image-container"
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                    transformOrigin: "top left",
                    cursor: dragging ? "grabbing" : "grab",
                    transition: dragging ? "none" : "transform 0.3s ease",
                    zIndex: 3,
                  }}
                  onMouseDown={handleDragStart}
                  onMouseMove={handleDrag}
                  onMouseUp={handleDragEnd}
                  onMouseLeave={handleDragEnd}
                  onWheel={handleWheelZoom}
                >
                  <img
                    src={`${constant.scannerUrl}/images/${image?.image}`}
                    alt="Zoomable"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      objectPosition: "top left",
                      userSelect: "none",
                      pointerEvents: "none",
                    }}
                    draggable={false}
                  />
                </div>
              </Stack>
            </Grid>

            {/* prefetchedData */}
            <Grid item md={3} lg={2} xs={12}>
              <Stack spacing={2} direction={"column"} alignItems="center">
                <p style={{ color: "blue", fontWeight: "bold" }}>Description</p>
                <span
                  style={{
                    fontSize: "16px",
                    wordBreak: "break-word",
                    textAlign: "center",
                  }}
                >
                  {formData?.description ? formData?.description : "N/A"}
                </span>
              </Stack>
            </Grid>

            {/* form */}
            <Grid item md={4} lg={3} xs={12} sz={{ height: "100%" }}>
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  height: isFullscreen ? "75vh" : "90vh", // Random test heights
                  // maxHeight: "90%", // fixed height for container
                  position: "relative",
                  overflowY: "auto",
                  "@media (max-height: 800px)": {
                    height: isFullscreen ? "80vh" : "65vh",
                  },
                  "@media (max-height: 600px)": {
                    height: isFullscreen ? "75vh" : "60vh",
                  },
                }}
              >
                {mobileNumbers.length === 0 && setMobileNumbers([""])}{" "}
                {/* Ensure at least one field */}
                {mobileNumbers.map((number, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      position: "relative",
                      backgroundColor:
                        metriMobileNumber?.mobile === number &&
                        metriMobileNumber?.repeat_count >= 15
                          ? "#FFF9C4"
                          : "transparent", // Light yellow for matrimony numbers
                      padding: 1,
                      borderRadius: 1,
                      width: "100%",
                    }}
                  >
                    {metriMobileNumber?.mobile === number &&
                    metriMobileNumber?.repeat_count >= 15 ? (
                      <Box sx={{ width: "100%" }}>
                        <Typography variant="body2" color="error">
                          Matrimony Numbers
                        </Typography>
                        <TextField
                          label="Mobile Number"
                          variant="outlined"
                          value={number}
                          onChange={(e) => handleChange(index, e.target.value)}
                          error={!!errors.mobileNumbers}
                          helperText={errors.mobileNumbers}
                          fullWidth
                        />
                      </Box>
                    ) : (
                      <TextField
                        label="Mobile Number"
                        variant="outlined"
                        value={number}
                        onChange={(e) => handleChange(index, e.target.value)}
                        error={!!errors.mobileNumbers}
                        helperText={errors.mobileNumbers}
                        fullWidth
                      />
                    )}
                    {index > 0 && (
                      <IconButton
                        color="error"
                        onClick={() => removeMobileField(index)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={addMobileField}
                  sx={{ marginTop: 1 }}
                >
                  Add Number
                </Button>
                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={formData?.name}
                  onChange={handleInputChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
                <Stack direction={"row"} spacing={1}>
                  <Button
                    variant={
                      formData.gender === "male" ? "contained" : "outlined"
                    }
                    size="small"
                    sx={{
                      padding: 1,
                      fontSize: 16,
                    }}
                    onClick={() => {
                      handleGenderChange("male");
                      setErrors((prev) => ({ ...prev, gender: "" }));
                    }}
                    fullWidth
                  >
                    Male
                  </Button>

                  <Button
                    variant={
                      formData.gender === "female" ? "contained" : "outlined"
                    }
                    size="small"
                    sx={{
                      padding: 1,
                      fontSize: 16,
                    }}
                    onClick={() => {
                      handleGenderChange("female");
                      setErrors((prev) => ({ ...prev, gender: "" })); // Clear error on selection
                    }}
                    fullWidth
                  >
                    Female
                  </Button>
                </Stack>
                {errors.gender && (
                  <Typography
                    color="error"
                    sx={{ fontSize: "14px", marginTop: "5px" }}
                  >
                    {errors.gender}
                  </Typography>
                )}
                <Stack direction={"row"}>
                  <Grid item xs={12} sx={{ marginRight: 1 }}>
                    <TextField
                      select
                      label="Year of Birth"
                      name="dob"
                      variant="outlined"
                      value={formData?.dob || ""}
                      onChange={handleInputChange}
                      fullWidth
                    >
                      {yearOptions.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Age"
                      name="age"
                      variant="outlined"
                      value={formData?.age || ""}
                      onChange={handleInputChange}
                      fullWidth
                    >
                      {Array.from({ length: 38 }, (_, i) => 18 + i).map(
                        (age) => (
                          <MenuItem key={age} value={age}>
                            {age}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Grid>
                </Stack>
                <TextField
                  select
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleInputChange}
                  label="Marital Status"
                  error={!!errors.maritalStatus}
                  helperText={errors.maritalStatus}
                >
                  {maritalStatus?.map((opt) => (
                    <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  freeSolo
                  loading={loading}
                  options={cityList.map((opt) => opt?.name_english) || []}
                  onInputChange={(event, newInputValue) => {
                    setSearchQuery(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      name="city"
                      sx={{ p: 0 }}
                      placeholder="Search for a city"
                    />
                  )}
                />
                <TextField
                  name="division"
                  value={formData.division}
                  onChange={handleInputChange}
                  label="Division"
                  error={!!errors.division}
                  helperText={errors.division}
                />
                <TextField
                  select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  error={!!errors.state}
                  helperText={errors.state}
                  label="State"
                >
                  {filterDetail?.stateList?.map((opt) => (
                    <MenuItem value={opt?.english}>{opt?.english}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  label="Country"
                  error={!!errors.country}
                  helperText={errors.country}
                >
                  {countryList?.map((opt) => (
                    <MenuItem value={opt?.name_english}>
                      {opt?.name_english}
                    </MenuItem>
                  ))}
                </TextField>
                {type === "hindu" ? (
                  <TextField
                    select
                    name="casteCategory"
                    value={formData.casteCategory}
                    onChange={handleInputChange}
                    label="Caste Category"
                  >
                    {casteCategory?.map((opt) => (
                      <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                    ))}
                  </TextField>
                ) : (
                  ""
                )}
                <Autocomplete
                  freeSolo
                  loading={loading}
                  options={subSectList?.map((opt) => opt?.english) || []}
                  onInputChange={(event, newInputValue) => {
                    setSearchSubSect(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Community"
                      name="sub_community"
                      sx={{ p: 0 }}
                      placeholder="Search for a Sub Community"
                    />
                  )}
                />
                <Autocomplete
                  freeSolo
                  value={
                    [
                      { english: "Don't Know" },
                      { english: "Not in biodata" },
                      ...(filterDetail?.sects?.map((opt) => ({
                        english: opt?.english,
                      })) || []),
                    ]?.find(
                      (opt) =>
                        opt?.english?.startsWith(formData?.community) ||
                        opt?.english === formData?.community
                    )?.english || ""
                  }
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "community", value: newValue },
                    })
                  }
                  options={[
                    "Don't Know",
                    "Not in biodata",
                    ...(filterDetail?.sects?.map((opt) => opt?.english) || []),
                  ]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Community"
                      name="community"
                      error={!!errors.community}
                      helperText={errors.community}
                    />
                  )}
                />
                {/* <TextField
                  select
                  name="community"
                  value={formData.community || ""}
                  onChange={handleInputChange}
                  label="Community"
                  error={!!errors.community}
                  helperText={errors.community}
                >
                  {[
                    "Don't Know",
                    "Not in biodata",
                    ...(filterDetail?.sects?.map((opt) => opt?.english) || []),
                  ].map((opt) => (
                    <MenuItem key={opt} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </TextField> */}
                <Autocomplete
                  freeSolo
                  multiple
                  options={[
                    "Don't Know",
                    "Not in biodata",
                    ...(filterDetail?.education?.map(
                      (opt) => opt?.name_english
                    ) || []),
                  ]}
                  value={
                    Array.isArray(formData?.education)
                      ? formData?.education
                      : []
                  }
                  onChange={(event, value) => handleEducation(event, value)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} style={{ marginRight: 8 }} />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Education"
                      placeholder="Select education"
                      error={!!errors.education}
                      helperText={errors.education}
                    />
                  )}
                />
                <Autocomplete
                  freeSolo
                  value={formData.profession}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "profession", value: newValue },
                    })
                  }
                  options={[
                    "Don't Know",
                    "Not in biodata",
                    ...(filterDetail?.employedIn?.english?.map(
                      (opt) => opt?.label
                    ) || []),
                  ]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profession"
                      name="profession"
                      error={!!errors.profession}
                      helperText={errors.profession}
                    />
                  )}
                />
                <TextField
                  select
                  name="height"
                  value={formData.height}
                  onChange={handleInputChange}
                  label="Height"
                >
                  {filterDetail?.height?.english?.map((opt) => (
                    <MenuItem value={opt?.label}>{opt?.label}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  name="physicalStatus"
                  value={formData.physicalStatus}
                  onChange={handleInputChange}
                  label="Physical Status"
                >
                  {disability?.map((opt) => (
                    <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  name="motherTongue"
                  value={formData.motherTongue}
                  onChange={handleInputChange}
                  label="Mother Tongue"
                >
                  {filterDetail?.motherTongueList?.english?.map((opt) => (
                    <MenuItem value={opt?.label}>{opt?.label}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Mail Id"
                  type="email"
                  name="gmail_id"
                  variant="outlined"
                  value={formData?.gmail_id}
                  onChange={handleInputChange}
                  fullWidth
                />
                <TextField
                  select
                  name="manglik"
                  value={formData.manglik}
                  onChange={handleInputChange}
                  label="Manglik"
                >
                  {filterDetail?.doshList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  name="income"
                  value={formData.income}
                  onChange={handleInputChange}
                  label="Income"
                >
                  <MenuItem value="Don't Know">Don't Know</MenuItem>
                  <MenuItem value="Not in biodata">Not in biodata</MenuItem>
                  {filterDetail?.incomeList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  name="familyStatus"
                  value={formData.familyStatus}
                  onChange={handleInputChange}
                  label="Family Status"
                >
                  {(type === "jain"
                    ? jainFamilyStatus
                    : hinduFamilyStatus
                  )?.map((opt) => (
                    <MenuItem key={opt?.value} value={opt?.value}>
                      {opt?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              {/* Sticky footer buttons */}

              <Grid
                item
                xs={12}
                sx={{
                  // position: "fixed",
                  bottom: "5px",
                  // right: "25px",
                  // height: "12%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => handleSubmit("update")}
                      variant="contained"
                      fullWidth
                      color="primary"
                      size="small"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Add
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    {type === "jain" ? (
                      <Button
                        onClick={() => handleMoveToData("move_to_hindu")}
                        variant="contained"
                        color="success"
                        fullWidth
                        size="small"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        Move To Hindu
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleMoveToData("move_to_jain")}
                        variant="contained"
                        color="success"
                        fullWidth
                        size="small"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        Move To Jain
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      onClick={handleOpen}
                      variant="contained"
                      color="error"
                      fullWidth
                      size="small"
                      sx={{ whiteSpace: "nowrap", marginBottom: 1 }}
                    >
                      Remove
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      onClick={() => setOpenSuspendModal(true)}
                      variant="contained"
                      size="small"
                      color="error"
                      fullWidth
                      sx={{ whiteSpace: "nowrap", marginBottom: 1 }}
                    >
                      Suspended
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Dialog open={openModal} onClose={handleClose}>
          <DialogTitle>Confirm Remove</DialogTitle>
          <DialogContent>
            <Stack direction={"row"}>
              <Button
                variant={
                  reasonType === "no_mobile_number" ? "contained" : "outlined"
                }
                size="small"
                sx={{
                  marginRight: 1,
                  padding: 1,

                  whiteSpace: "nowrap", // Ensures text stays on one line
                  minWidth: "auto", // Prevents excessive width
                }}
                onClick={() => handleReasonType("no_mobile_number")}
                fullWidth
              >
                No Mobile Number
              </Button>

              <Button
                variant={reasonType === "no_image" ? "contained" : "outlined"}
                size="small"
                sx={{
                  padding: 1,
                }}
                onClick={() => handleReasonType("no_image")}
                fullWidth
              >
                No Image
              </Button>
              <Button
                variant={reasonType === "blur_image" ? "contained" : "outlined"}
                size="small"
                sx={{
                  padding: 1,
                }}
                onClick={() => handleReasonType("blur_image")}
                fullWidth
              >
                Blur Image
              </Button>
              <Button
                variant={
                  reasonType === "user_details_not_updated"
                    ? "contained"
                    : "outlined"
                }
                size="small"
                sx={{
                  padding: 1,
                }}
                onClick={() => handleReasonType("user_details_not_updated")}
                fullWidth
              >
                User Details Not Updated
              </Button>
            </Stack>
            <TextField
              label="Reason"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              margin="normal"
              error={!!errors.reason}
              helperText={errors.reason}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleRemove} variant="contained" color="error">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSuspendModal}
          onClose={() => setOpenSuspendModal(false)}
        >
          <DialogTitle>Confirm Suspend</DialogTitle>
          <DialogContent>
            <TextField
              label="Reason"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={suspendedReason}
              onChange={(e) => setSuspendedReason(e.target.value)}
              margin="normal"
              error={!!errors.suspendedReason}
              helperText={errors.suspendedReason}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenSuspendModal(false)}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSuspendedTask}
              variant="contained"
              color="error"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddModal} onClose={handleAddModal} maxWidth={"xs"}>
          <CardHeader
            sx={{ backgroundColor: "red", color: "White", padding: "0px 15px" }}
            title="Matrimony Numbers"
            action={
              <Tooltip title="Close">
                <IconButton
                  className="CloseBtn"
                  onClick={() => {
                    handleAddModal();
                  }}
                >
                  <Iconify icon="mdi:cancel-circle-outline" />
                </IconButton>
              </Tooltip>
            }
          />
          <DialogContent>
            <Stack spacing={2} sx={{ padding: 2 }}>
              <Stack direction={"row"} spacing={2}>
                <Button
                  variant={sourceTable === "jain" ? "contained" : "outlined"}
                  onClick={() => setSourceTable("jain")}
                  fullWidth
                >
                  Jain
                </Button>
                <Button
                  variant={sourceTable === "hindu" ? "contained" : "outlined"}
                  onClick={() => setSourceTable("hindu")}
                  fullWidth
                >
                  Hindu
                </Button>
              </Stack>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: "30%" }}>
                  <InputLabel id="country-code-label">Code</InputLabel>
                  <Select
                    labelId="country-code-label"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    label="Code"
                  >
                    {countryCodes.map((item) => (
                      <MenuItem value={item?.code}>
                        {item?.code} ({item?.country})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  sx={{ flex: 1 }}
                  label="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Box>
              <TextField
                fullWidth
                label="Enter Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
              <Button variant="contained" onClick={handleOrganizationSubmit}>
                Add
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* cards */}
        <Grid container spacing={2}>
          {prefoundData?.jain_data?.length > 0 &&
            prefoundData?.jain_data?.map((bioData) => {
              return (
                <Grid item lg={4} key={bioData?.id} md={6} sm={6} xs={12}>
                  <ScannerBioDataUserCard bioData={bioData} type="jain" />
                </Grid>
              );
            })}
          {prefoundData?.hindu_data?.length > 0 &&
            prefoundData?.hindu_data?.map((bioData) => {
              return (
                <Grid item lg={4} key={bioData?.id} md={6} sm={6} xs={12}>
                  <ScannerBioDataUserCard bioData={bioData} type="hindu" />
                </Grid>
              );
            })}
        </Grid>

        <Dialog
          open={reportPopup}
          onClose={() => setReportPopup(false)}
          maxWidth={"lg"}
          fullWidth
        >
          <DialogTitle> {user?.nameEnglish} Performance Report </DialogTitle>
          <DialogContent>
            <Card>
              <CardContent sx={{ pb: 3 }}>
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Timing</InputLabel>
                        <Select
                          value={filter?.staff_filter_timing || ""}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              staff_filter_timing: e.target.value,
                            })
                          }
                        >
                          {timingOption.map((item) => (
                            <MenuItem key={item?.value} value={item?.value}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={fetchStaffAssignTaskReport}
                      >
                        Apply Filter
                      </Button>
                    </Grid>
                  </>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Assign Task"
                      total={count?.total_assign_task}
                      color="secondary"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Pending Task"
                      total={count?.pending_task}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Update Task"
                      total={count.update_task}
                      color="info"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Approve Task"
                      total={count.approve_task}
                      color="success"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Remove Task"
                      total={count.remove_task}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Suspended Task"
                      total={count.suspend_task}
                    />
                  </Grid>
                  {type == "hindu" && (
                    <Grid item xs={12} sm={6} md={2}>
                      <AnalyticsWidgetSummary
                        title="Move To Jain"
                        total={count?.move_to_jain}
                        color="warning"
                      />
                    </Grid>
                  )}
                  {type == "jain" && (
                    <Grid item xs={12} sm={6} md={2}>
                      <AnalyticsWidgetSummary
                        title="Move To Hindu"
                        total={count?.move_to_hindu}
                        color="warning"
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setReportPopup(false)} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ViewWorkFromHomeStaffTask;
