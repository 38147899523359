import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { createGroupLink, groupLinkData } from "src/service";
import CloseIcon from "@mui/icons-material/Close";
import GroupAddedLinkCard from "src/sections/@dashboard/user/cards/GroupAddedLinkCard";
import { PATH_DASHBOARD } from "../../routes/paths";

const AddGroupLink = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [groupType, setGroupType] = useState("");
  const [dataType, setDataType] = useState("");
  const [groupLinks, setGroupLinks] = useState([]); // Dynamic Data
  const [link, setLink] = useState("");
  const [selected, setSelected] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [add, setAdd] = useState(false);
  const [staffNumber, setStaffNumber] = useState("");
  const [bioNotes, setBioNotes] = useState("");
  const [activeCard, setActiveCard] = useState("entered_link");
  const [check, setCheck] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({});
  const [error, setError] = useState("");
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Fetch Group Link Data from API
  const getGroupLinkData = async () => {
    try {
      const updatedFilter = { ...filter, type: activeCard };
      let res = await groupLinkData(page, rowsPerPage, updatedFilter);
      setGroupLinks(res?.data?.data[1] || []); // Set fetched data
    } catch (error) {
      console.error("Error fetching group links:", error);
    }
  };

  // Call API on activeCard change
  useEffect(() => {
    getGroupLinkData(page, rowsPerPage, filter);
  }, [activeCard, page, rowsPerPage, filter]);

  const handleClose = () => {
    setOpen(false);
    navigate(PATH_DASHBOARD?.general?.grouplinks);
  };

  // Create New Link
  const createLink = async () => {
    if (!link) {
      enqueueSnackbar("Please enter a link", { variant: "warning" });
      return;
    }
    if (!dataType) {
      enqueueSnackbar("Please select a Group Data Type", {
        variant: "warning",
      });
      return;
    }
    if (!groupType) {
      enqueueSnackbar("Please select a Type Of Group", { variant: "warning" });
      return;
    }

    setIsLoading(true);
    const payload = {
      group_link_name: link,
      entry_data_group_type: dataType,
      entry_data_type_of_data: groupType,
      join_notes: notes,
    };

    try {
      const response = await createGroupLink(payload);
      if (response?.data?.status) {
        enqueueSnackbar("Link added successfully!", { variant: "success" });

        // Clear fields
        setLink("");
        setDataType("");
        setGroupType("");
        setNotes("");
        getGroupLinkData(); // Refresh list
      } else {
        enqueueSnackbar(response?.data?.message || "Error adding link", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error while creating link:", error);
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleToggleAll = () => setSelectAllChecked(!selectAllChecked);
  const handleToggleOne = (id) => setSelectedId(id);

  const onSelectRow = (id) => {
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{ backgroundColor: "red", color: "white", textAlign: "center" }}
      >
        Add New Link
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3,
        }}
      >
        {/* Form Section - No Scroll */}
        <TextField
          label="Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          fullWidth
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" color="error" fullWidth>
              Jain
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="error" fullWidth disabled>
              Hindu
            </Button>
          </Grid>
        </Grid>
        <FormControl fullWidth>
          <InputLabel>Group Data Type</InputLabel>
          <Select
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
          >
            <MenuItem value="Low Data">Low Data - 50</MenuItem>
            <MenuItem value="Middle Data">Middle Data - 100</MenuItem>
            <MenuItem value="High Data">High Data - 200</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Type Of Group</InputLabel>
          <Select
            value={groupType}
            onChange={(e) => setGroupType(e.target.value)}
          >
            <MenuItem value="Only Admin Can Send Information">
              Only Admin Can Send Information
            </MenuItem>
            <MenuItem value="All Can Send Information">
              All Can Send Information
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Write Notes"
          multiline
          rows={2}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
        />

        {error && <p style={{ color: "red" }}>{error}</p>}

        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={createLink}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>

        {/* Cards Section - Scrollable */}
        <div
          style={{
            maxHeight: "300px", // Adjust as needed
            overflowY: "auto",
            marginTop: "10px",
            padding: "5px",
          }}
        >
          <Grid container spacing={2}>
            {groupLinks.map((groupLink) => (
              <Grid item lg={4} md={6} sm={6} xs={12} key={groupLink.id}>
                <GroupAddedLinkCard
                  selected={selected.includes(groupLink?.id)}
                  onSelectRow={() => onSelectRow(groupLink?.id)}
                  update={update}
                  groupLink={groupLink}
                  setUpdate={setUpdate}
                  setShowUpdate={setShowUpdate}
                  setAdd={setAdd}
                  staffNumber={staffNumber}
                  getGroupLinkData={getGroupLinkData}
                  bioNotes={bioNotes}
                  goToTop={goToTop}
                  handleToggleAll={handleToggleAll}
                  selectAllChecked={selectAllChecked}
                  selectedId={selectedId}
                  activeCard={activeCard}
                  check={check}
                  handleToggleOne={handleToggleOne}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddGroupLink;
