import { useState, useEffect } from "react";
import {
  Tooltip,
  Button,
  TextField,
  CardHeader,
  Typography,
  IconButton,
  Checkbox,
} from "@mui/material";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useSnackbar } from "../../../../components/snackbar";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
// ----------------------------------------------------------------------

const ratingArray = [
  { key: 4, title: "A=80-100%" },
  { key: 3, title: "B=40-80%" },
  { key: 2, title: "C=1-40%" },
  { key: 1, title: "D=0%" },
];

export default function GroupAddedLinkCard({
  selected,
  handleToggleOne,
  check,
  groupLink,
  update,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [costModal, setCostModal] = useState(false);
  const [mobileModel, setMobileModel] = useState(false);
  const [noteModalValue, setNoteModalValue] = useState("");
  const [costValue, setCostValue] = useState("");
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const [newRow, setNewRow] = useState(groupLink);
  const [mobileValue, setMobileValue] = useState(newRow?.mobile || "");
  const [item, setItem] = useState({ item: update });
  const [note, setNote] = useState([]);
  const [cost, setCost] = useState([]);
  const [date, setDate] = useState([]);

  useEffect(() => {
    setItem({ item: update });
    if (update?.notes) setNote(update?.notes);
    if (update?.final_cost) setCost(update?.final_cost);
  }, [update]);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleCopyLink = (item) => {
    copy(item);
    enqueueSnackbar("Copied!");
  };

  const styles = {
    smallNormalText: {
      marginTop: "0px",
      fontWeight: "normal",
      color: "rgb(105, 105, 105)",
      fontSize: 12.5,
      whiteSpace: "nowrap",
    },
  };

  // const onStatus = async (obj) => {
  //     obj.id = newRow?.id
  //     let res = await updateOfflineUsers(obj.id, obj)
  //     if (res?.data?.status) {
  //         enqueueSnackbar(res?.data?.message);
  //     }
  //     else enqueueSnackbar(res?.data?.message, { variant: 'error' });
  //     setNewRow({ ...newRow, ...obj });
  // };

  // const onDelete = async () => {
  //     let obj = {
  //         "id": bioData?.id
  //     };
  //     try {
  //         let res = await deleteOfflineUsers(obj, bioData?.id);

  //         if (res?.data?.status) {
  //             enqueueSnackbar(res?.data?.message);
  //             getOffineUsers();
  //         } else {
  //             enqueueSnackbar(res?.data?.message, { variant: 'error' });
  //         }
  //     } catch (error) {
  //         console.error("Error deleting user:", error);
  //         enqueueSnackbar("An error occurred while deleting the user.", { variant: 'error' });
  //     }
  // };

  // send whatsapp msg
  const whatsappNow = (mobile) => {
    window.location.href = "https://wa.me/91" + `${mobile}`;
  };
  function callNow(mobile) {
    window.location.href = `tel:${mobile}`;
  }

  useEffect(() => {
    currentDate();
  }, []);

  const currentDate = () => {
    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    setDate(today);
  };

  return (
    <div className="offlineCard m-2">
      <div
        className="displayBtwPro primaryBg light"
        style={{
          padding: "8px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <div>
          {check && (
            <Checkbox
              size="small"
              checked={selected}
              onChange={() => handleToggleOne(newRow.id)}
            />
          )}
          {newRow?.id && (
            <Typography variant="subtitle1"> #{newRow?.id} &nbsp;</Typography>
          )}
          {newRow?.group_link_name && (
            <Typography
              variant="subtitle1"
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                handleCopyLink(newRow?.group_link_name);
              }}
            >
              {newRow?.group_link_name}{" "}
              <ContentCopyOutlinedIcon className="fs-16 ms-2" />
            </Typography>
          )}
        </div>
      </div>
      <div className="p-2 px-3" style={{ background: "#fff" }}>
        {/* Header Section start*/}
        <div className="row pb-2">
          <div className="col-lg-4">
            <span className="secondaryColor fw-500 fs-14 px-2">
              ES :{" "}
              <span style={{ color: "#000" }}>
                {newRow?.EGROUPLINK?.name?.english}
              </span>
            </span>
            <hr className="my-1" />
            <span className="fs-12">{`${moment(newRow?.created_at).format(
              "DD-MM-YY, h:mm"
            )}`}</span>
          </div>
          {newRow?.whatsapp_status && (
            <div className="col-lg-4">
              <span className="secondaryColor fw-500 fs-14">
                WS :{" "}
                <span style={{ color: "#000" }}>
                  {newRow?.wStaff?.name?.english}
                </span>
              </span>
              <hr className="my-1" />
              <span className="fs-12">{`${moment(
                newRow?.whatsapp_date_time
              ).format("DD-MM-YY, h:mm")}`}</span>
            </div>
          )}
          {newRow?.call_status && (
            <div className="col-lg-4">
              <span className="secondaryColor fw-500 fs-14 px-2">
                CS :
                <span style={{ color: "#000" }}>
                  {newRow?.call_By?.name?.english}
                </span>
              </span>
              <hr className="my-1" />
              <span className="fs-12">{`${moment(newRow?.call_date_time).format(
                "DD-MM-YY, h:mm"
              )}`}</span>
            </div>
          )}
        </div>
        {/* Header Section end */}

        {/* LAST SECTION ADD NOTE AND DONE */}
        <div className="d-flex justify-content-between align-items-center">
          <Button variant="outlined" size="small" className="m-1 " fullWidth>
            <NoteAltOutlinedIcon className="pe-1" /> Add Notes
          </Button>
          <Button
            variant="contained"
            size="small"
            className="m-1 group-link-donebtn"
            style={{ fontSize: "14px" }}
            fullWidth
          >
            Done <VerifiedIcon className="ps-2" />
          </Button>
        </div>
        {/* LAST SECTION ADD NOTE AND DONE */}
      </div>
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={mobileModel}
        onClose={() => {
          setMobileModel(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setMobileModel(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Edit Mobile"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Mobile"
                type="number"
                placeholder="Type  here.."
                value={mobileValue}
                onChange={(e) => {
                  setMobileValue(e.target.value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    // onStatus({
                    //     mobile: mobileValue,
                    //     mobiles: {
                    //         mobile: mobileValue,
                    //         is_whatsapp: update?.mobiles?.is_whatsapp,
                    //         is_call_done: update?.mobiles?.is_call_done,
                    //         time: new Date(),
                    //     },
                    // });
                    setNewRow({ ...newRow, mobile: mobileValue });
                    setMobileModel(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
      {/* add notes */}
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={noteModal}
        onClose={() => {
          setNoteModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setNoteModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Notes"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Note"
                placeholder="Type  here.."
                value={note}
                onChange={(e, value) => {
                  setNoteModalValue(e.target.value);
                  setNote(value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    // onStatus({ notes: noteModalValue });
                    setNewRow({ ...newRow, notes: noteModalValue });
                    setNoteModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />

      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={costModal}
        onClose={() => {
          setCostModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setCostModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Cost"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Cost"
                placeholder="Type  here.."
                value={cost}
                onChange={(e, value) => {
                  setCostValue(e.target.value);
                  setCost(value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    // onStatus({ final_cost: costValue });
                    setNewRow({ ...newRow, final_cost: costValue });
                    setCostModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}
