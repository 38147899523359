import PropTypes from "prop-types";
import { TableRow, TableCell, TableHead, Switch } from "@mui/material";

FormTableHead.propTypes = {
  sx: PropTypes.object,
  orderBy: PropTypes.string,
  order: PropTypes.oneOf(["asc", "desc"]),
  headLabel: PropTypes.array,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  onToggleStatus: PropTypes.func,
};

export default function FormTableHead({
  filter,
  setFilter,
  orderBy,
  order,
  headLabel,
  sx,
  onToggleStatus,
}) {
  return (
    <TableHead sx={sx}>
      <TableRow className="onlineTable">
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              whiteSpace: "nowrap",
            }}
          >
            {"public" === headCell.id ? (
              <Switch
                checked={filter?.status === true}
                onChange={() => {
                  let newStatusFilter = !filter?.status;
                  setFilter({ ...filter, status: newStatusFilter });
                }}
                size="small"
              />
            ) : null}
            {"master_status" === headCell.id ? (
              <Switch
                checked={filter?.master_status === true}
                onChange={() => {
                  let newStatusFilter = !filter?.master_status;
                  setFilter({ ...filter, master_status: newStatusFilter });
                }}
                size="small"
              />
            ) : null}
            {"staff_status" === headCell.id ? (
              <Switch
                checked={filter?.staff_status === true}
                onChange={() => {
                  let newStatusFilter = !filter?.staff_status;
                  setFilter({ ...filter, staff_status: newStatusFilter });
                }}
                size="small"
              />
            ) : null}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
