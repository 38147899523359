import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  IconButton,
  MenuItem,
  Grid,
  Box,
  CardHeader,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { getSectList, newUser } from "src/service";
import { useSnackbar } from "../../components/snackbar";
import { useAuthContext } from "src/auth/useAuthContext";

const children = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
];

const sect = [
  { id: 1, name: "Digambar" },
  { id: 2, name: "Shwetambar" },
];

const gender = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];

const createdFor = [
  { id: 1, name: "Self" },
  { id: 2, name: "Son" },
  { id: 3, name: "Daughter" },
  { id: 4, name: "Brother" },
  { id: 5, name: "Sister" },
  { id: 6, name: "Relative" },
  { id: 7, name: "Friend" },
];

const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

export default function NewUser({ open, item, handleClose, filterDetail }) {
  const [show, setShow] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [age, setAge] = useState([]);
  const { user } = useAuthContext();
  const [sectList, setSectList] = useState([]);

  const generateAgeArray = (gender = "male") => {
    gender = (gender || "male").toLowerCase();
    let startAge = gender === "male" ? 21 : 18;
    const ageArr = [];
    for (let index = startAge; index < 81; index++) {
      ageArr.push({ label: `${index} yrs`, value: index });
    }

    return ageArr;
  };
  const getSect = async () => {
    try {
      let res = await getSectList();

      if (res?.data?.data?.sects && Array.isArray(res.data.data.sects)) {
        const filteredSect = [];

        res?.data?.data?.sects?.forEach((data) => {
          if (data?.master_status) filteredSect.push(data);
        });

        setSectList(filteredSect);
      } else {
        console.error("Invalid or missing sects data in API response:", res);
        setSectList([]);
      }
    } catch (error) {
      console.error("Error fetching sect list:", error);
      setSectList([]);
    }
  };

  useEffect(() => {
    if (open) {
      getSect();
    }
  }, [open]);

  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile Number must be in 10 digit")
      .required("Mobile Number is required"),
    nameEnglish: Yup.string().required("Name is required"),
    first_formGenderEnglish: Yup.string().required("Gender is required"),
    // first_formMaritalStatusEnglish: Yup.string().required('Marital Status is required'),
    first_formProfileEnglish: Yup.string().required("Created for is required"),
    second_formStateEnglish: Yup.string().required("State is required"),
    third_formSectEnglish: Yup.string().required("Sect is required"),
    _age: Yup.string().required("Age is required"),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    watch,
    getValues,
    setError,
    handleSubmit,
    formState: {},
  } = methods;

  const watchGender = watch("first_formGenderEnglish");
  useEffect(() => {
    let vGen = getValues("first_formGenderEnglish");
    let ageTemp = generateAgeArray(vGen);
    setAge(ageTemp);
  }, [watchGender]);

  const watchChildren = watch("first_formMaritalStatusEnglish");
  useEffect(() => {
    let v = getValues("first_formMaritalStatusEnglish");
    if (v === "Widow/widower" || v === "Divorce" || v === "Awiting divorce")
      setShow(true);
    else setShow(false);
  }, [watchChildren]);

  const onSubmit = async (data) => {
    let obj = {
      mobile: data?.mobile,
      nameEnglish: data?.nameEnglish,
      first_formGenderEnglish: data?.first_formGenderEnglish,
      first_formMaritalStatusEnglish: data?.first_formMaritalStatusEnglish,
      first_formProfileEnglish: data?.first_formProfileEnglish,
      first_formNoOfChildren: data?.first_formNoOfChildren,
      second_formStateEnglish: data?.second_formStateEnglish,
      third_formSectEnglish: data?.third_formSectEnglish,
      _age: data?._age,
      created_by_staff_id: user?.id,
    };
    try {
      let res = await newUser(obj);
      if (res?.data?.status) {
        enqueueSnackbar(res.data.message);
        handleClose();
      } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };

  const handleInput = (event) => {
    event.target.value = event.target.value.replace(/[^A-Za-z ]/g, "");
  };

  const handleInputMobile = (event) => {
    if (event.target.value.length > 10) {
      event.target.value = event.target.value.slice(0, 10);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <CardHeader
        action={
          <Tooltip title="Close">
            <IconButton
              color="error"
              className="CloseBtn"
              onClick={() => {
                handleClose();
              }}
            >
              <Iconify icon="mdi:cancel-circle-outline" />
            </IconButton>
          </Tooltip>
        }
        title={"New User"}
        sx={{ ml: 47 }}
      />
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                rowGap={3}
                columnGap={2}
                // display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <Stack spacing={3} sx={{ mt: 4, ml: 25, width: "50%" }}>
                  <RHFTextField
                    onInput={handleInputMobile}
                    name="mobile"
                    label="Mobile Number"
                  />
                  <RHFTextField
                    onInput={handleInput}
                    name="nameEnglish"
                    label="Name"
                  />
                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="first_formGenderEnglish"
                    label="Select Gender"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {gender?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="_age"
                    label="Select Age"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {age?.map((i) => (
                      <MenuItem
                        key={i.value}
                        value={i.value}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  {/* <RHFTextField
                    native
                    fullWidth
                    select
                    name="third_formSectEnglish"
                    label="Select Sect"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {sectList?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i?.name}
                      </MenuItem>
                    ))}
                  </RHFTextField> */}
                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="third_formSectEnglish"
                    label="Community"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {sectList?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i?.english} // Ensure the value is the English name
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i?.english}{" "}
                        {/* Display the English sect name in the dropdown */}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="first_formProfileEnglish"
                    label="Created For"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {createdFor?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="first_formMaritalStatusEnglish"
                    label="Marital status"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {maritalStatus?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  {show && (
                    <RHFTextField
                      native
                      fullWidth
                      select
                      name="first_formNoOfChildren"
                      label="Select No of Children"
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: "auto" },
                        textTransform: "capitalize",
                      }}
                    >
                      {children?.map((i) => (
                        <MenuItem
                          key={i?.id}
                          value={i.name}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {i.name}
                        </MenuItem>
                      ))}
                    </RHFTextField>
                  )}

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="second_formStateEnglish"
                    label="Select State"
                    // onChange={(e) => { getCityList(e.target.value) }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {/* <MenuItem value="" /> */}
                    {filterDetail?.stateList?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i?.english}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i?.english}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                </Stack>
                <div style={{ textAlign: "center" }}>
                  <LoadingButton
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    // onClick={(e) => {
                    //     userCreated();
                    // }}
                    // loading={isSubmitSuccessful || isSubmitting}
                    sx={{
                      my: 2,
                      bgcolor: "text.primary",
                      width: 220,
                      justifyContent: "center",
                      typography: "body1",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    ADD
                  </LoadingButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
