import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography,
  CardHeader,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  Select,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useAuthContext } from "src/auth/useAuthContext";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";
import {
  getActiveSectionDataApi,
  getExcelDataViewApi,
  getExcelSectionsApi,
  getExcelSectionTypeApi,
} from "src/service";
import ExcelDataCard from "src/sections/@dashboard/user/cards/ExcelDataCard";
import { TablePaginationCustom, useTable } from "src/components/table";

export default function ViewExcelSection() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onChangePageButton,
  } = useTable();

  const contxt = useAuthContext();
  const navigate = useNavigate();
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [excelSectionType, setExcelSectionType] = useState([]);
  const [activeSection, setActiveSection] = useState([]);
  const [activeSectionData, setActiveSectionData] = useState([]);
  const [excelSections, setExcelSections] = useState([]);
  const [selectedType, setSelectedType] = useState("state");
  const [selectedId, setSelectedId] = useState([]);

  const [total, setTotal] = useState({
    total_data_count: 0,
  });

  const getExcelSectionTypeList = async () => {
    try {
      const response = await getExcelSectionTypeApi();

      if (response?.status === 200) {
        const responseData = response?.data?.data || [];
        setExcelSectionType(responseData);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const getAllSections = async () => {
    try {
      const response = await getExcelSectionsApi({ type: selectedType });
      console.log(response?.data?.data);

      if (response?.status === 200) {
        const responseData = response?.data?.data || [];
        setExcelSections(responseData);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const getActiveSectionData = async (sectionName) => {
    try {
      const response = await getActiveSectionDataApi({
        sectionName: sectionName,
      });
      console.log(response?.data?.data);

      if (response?.status === 200) {
        const { getActiveSectionData, totalCount } = response?.data?.data || [];
        setActiveSectionData(getActiveSectionData);
        setTotal({
          total_data_count: totalCount || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const getSingleViewRow = async (id) => {
    try {
      const res = await getExcelDataViewApi(id);
      if (res?.data?.status) {
        setViewData(res?.data?.data);
        setOpenView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardSelect = (id) => {
    if (selectedId.includes(id)) {
      setSelectedId(selectedId.filter((item) => item !== id));
    } else {
      setSelectedId([...selectedId, id]);
    }
  };

  useEffect(() => {
    getExcelSectionTypeList();
    getAllSections();
  }, []);

  return (
    <>
      <Helmet>
        <title> All Sections</title>
      </Helmet>
      <Card>
        <CardHeader
          title={<Typography variant="h4">All Sections</Typography>}
          action={
            <Button
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          }
        />
        <CardContent sx={{ pb: 3 }}>
          <Grid container spacing={2}>
            <>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Section Type</InputLabel>
                  <Select
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    {excelSectionType.map((item, i) => (
                      <MenuItem key={i} value={item?.type}>
                        {item?.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={getAllSections}
                >
                  Apply Filter
                </Button>
              </Grid>
            </>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ mt: 2, display: "flex", flexWrap: "wrap" }}
          >
            {excelSections.map((item, index) => (
              <Grid
                item
                key={index}
                sx={{ width: "13%", minHeight: "80px" }} // Ensure enough space
                onClick={() => {
                  setActiveSection(item?.name);
                  getActiveSectionData(item?.name);
                }}
              >
                <AnalyticsWidgetSummary
                  title={item?.name}
                  total={item?.total || 0}
                  color="secondary"
                  sx={{
                    display: "flex", // Enables flexbox
                    flexDirection: "column", // Aligns content in a column
                    alignItems: "center", // Centers horizontally
                    justifyContent: "center", // Centers vertically
                    height: "80px", // Keeps height limited
                    padding: "8px", // Reduces padding if necessary
                    textAlign: "center", // Ensures text is centered
                    "& .MuiTypography-root": {
                      fontSize: "14px", // Adjust font size for both title & count
                    },
                    "& .MuiTypography-h6": {
                      fontSize: "15px", // Adjust font size for the count (if it's h6)
                    },
                  }}
                  style={{
                    cursor: "pointer",
                    border: activeSection === item?.name ? "2px solid red" : "",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>

        <CardContent sx={{ py: 2, px: 2 }}>
          <Grid container spacing={4}>
            {activeSectionData.map((row, index) => (
              <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                <ExcelDataCard
                  data={row}
                  onView={() => getSingleViewRow(row?.id)}
                  onSelect={() => handleCardSelect(row?.id)}
                  isSelected={selectedId.includes(row?.id)}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>

        <Dialog
          open={openView}
          onClose={() => {
            setOpenView(false);
            setViewData(null);
          }}
          maxWidth={"lg"}
          fullWidth
        >
          <DialogContent>
            <CardHeader
              title="View Detail"
              action={
                <>
                  <Tooltip title="Close">
                    <IconButton
                      color="error"
                      className="CloseBtn"
                      onClick={() => {
                        setOpenView(false);
                        setViewData(null);
                      }}
                    >
                      <Iconify icon="mdi:cancel-circle-outline" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
            <Card>
              <CardContent sx={{ pb: 3 }}>
                <Grid container spacing={2}>
                  {viewData &&
                    Object.entries(viewData)
                      ?.filter(([key, value]) => value)
                      ?.map(([key, value]) => (
                        <Grid item xs={12} sm={6} key={key}>
                          <Typography variant="subtitle2" color="textSecondary">
                            {key?.replace(/_/g, " ")?.toUpperCase()}:
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            {value}
                          </Typography>
                        </Grid>
                      ))}
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>

        <TablePaginationCustom
          count={total.total_data_count}
          page={page}
          onChangePageButton={onChangePageButton}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
