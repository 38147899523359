import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useAuthContext } from "src/auth/useAuthContext";

const IDLE_TIME_LIMIT = 30 * 60 * 1000;
const CHECK_INTERVAL = 60 * 1000;

const UseTrackingActivity = () => {
  const idleTimeout = useRef(null);
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const activityChannel = new BroadcastChannel("user-activity");

  const handleLogout = () => {
    console.log("🚨 User inactive. Logging out...");
    logout();
    navigate("/", { replace: true });
    localStorage.removeItem("lastActivity");
  };

  const updateLastActivity = () => {
    localStorage.setItem("lastActivity", Date.now().toString());
    activityChannel.postMessage("active");
  };

  const handleUserActivity = () => {
    if (idleTimeout.current) clearTimeout(idleTimeout.current);

    updateLastActivity();

    idleTimeout.current = setTimeout(() => {
      checkInactivity();
    }, IDLE_TIME_LIMIT);
  };

  const checkInactivity = () => {
    const lastActivity =
      Number(localStorage.getItem("lastActivity")) || Date.now();
    const elapsedTime = Date.now() - lastActivity;

    if (elapsedTime >= IDLE_TIME_LIMIT) {
      handleLogout();
    } else {
      handleUserActivity();
    }
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
    events.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        checkInactivity();
      }
    });

    activityChannel.onmessage = (event) => {
      if (event.data === "active") {
        updateLastActivity();
      }
    };

    handleUserActivity();

    const interval = setInterval(checkInactivity, CHECK_INTERVAL);

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      document.removeEventListener("visibilitychange", checkInactivity);
      clearInterval(interval);
      if (idleTimeout.current) clearTimeout(idleTimeout.current);
      activityChannel.close();
    };
  }, []);

  return null;
};

export default UseTrackingActivity;
