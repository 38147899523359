import { useEffect, useState } from "react";
import SvgColor from "../../svg-color";
import { StyledCard } from "../styles";

export default function FullScreenOptions({ style, onFullscreenChange }) {
  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
      onFullscreenChange(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
      onFullscreenChange(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreen = !!document.fullscreenElement;
      setFullscreen(isFullscreen);
      onFullscreenChange(isFullscreen);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [onFullscreenChange]);

  return (
    <StyledCard
      selected={fullscreen}
      onClick={onToggleFullScreen}
      sx={{
        ...style,
        typography: "subtitle2",
        "& .svg-color": {
          ml: 1,
          width: 16,
          height: 16,
        },
      }}
    >
      {fullscreen ? "Exit Fullscreen" : "Fullscreen"}
      <SvgColor
        src={`/assets/icons/setting/${
          fullscreen ? "ic_exit_full_screen" : "ic_full_screen"
        }.svg`}
      />
    </StyledCard>
  );
}
