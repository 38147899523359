import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Button,
  TextField,
  CardHeader,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  IconButton,
  DialogContent,
  Dialog,
} from "@mui/material";
import { _socials } from "../../../../_mock/arrays";
import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp";
import Image from "../../../../components/image";
import Switch from "@mui/material/Switch";
import {
  updateBiodata,
  deleteBiodata,
  bioDataShadiDoneMove,
  restoreBiodataUser,
  getStateListAPI,
  getNotes,
  getDetailList,
} from "src/service";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useSnackbar } from "../../../../components/snackbar";
import { LoadingButton } from "@mui/lab";
import constant from "src/Constant";
import { useAuthContext } from "src/auth/useAuthContext";
import { Link as RouterLink } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import moment from "moment";
import StaffToPayNewUser from "src/pages/UserFormData/StaffToPayNewUser";
import useCopyImageWithId from "src/hooks/useCopyImageWithId";
import NewUser from "src/pages/UserFormData/NewUser";
// import Dialog from "src/theme/overrides/Dialog";
// ----------------------------------------------------------------------

const ratingArray = [
  { key: 4, title: "A=80-100%" },
  { key: 3, title: "B=40-80%" },
  { key: 2, title: "C=1-40%" },
  { key: 1, title: "D=0%" },
];

export default function BioDataCard({
  selectedId,
  handleToggleAll,
  bioData,
  goToTop,
  getPendingRavcListData,
  getBiodataDetail,
  bioNotes,
  staffName,
  filterDetail,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);
  const [noteModal, setNoteModal] = useState(false);
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const [newRow, setNewRow] = useState(bioData);
  const [noteModalValue, setNoteModalValue] = useState(newRow?.notes || "");
  const [notes, setNote] = useState([]);
  const [date, setDate] = useState([]);
  const contxt = useAuthContext();
  const [mobileValue, setMobileValue] = useState([]);
  const [viewImage, SetViewImage] = useState({ open: false, path: "" });
  const [open, setOpen] = useState(false);
  const [historyPopupOpen, setHistoryPopupOpen] = useState(false);
  const [nphistoryPopupOpen, setNpHistoryPopupOpen] = useState(false);
  const [nchistoryPopupOpen, setNcHistoryPopupOpen] = useState(false);
  const entryId = newRow?.entry_id;
  const updateId = newRow?.update_id;
  const matchingObject = staffName?.filter((item) => item?.id === entryId);
  const updateIdName = staffName?.filter((item) => item?.id === updateId);

  const { copyImageWithId, canvasRef } = useCopyImageWithId(
    `Jain${bioData?.id}`
  );

  const copyImageById = async (url) => {
    copyImageWithId(url);
  };

  const sections = [
    { id: "biodata_entries", label: "Biodata" },
    { id: "new_offline_user_two", label: "offline" },
    { id: "whatsapp_response", label: "WhatsApp response" },
    { id: "incoming_call_entries_id", label: "incoming" },
    { id: "paid_promotion", label: "paid promotion" },
    { id: "social_media", label: "social media" },
    { id: "group_service", label: "group service" },
    { id: "online_user", label: "online user" },
  ];

  const availableSections = sections
    .filter((section) => {
      const key =
        section.id === "incoming_call_entries_id"
          ? section.id
          : `${section.id}_id`;
      return newRow[key] !== null && newRow[key] !== 0;
    })
    .map((section) => section.label);

  const allNull = sections.every((section) => {
    const key =
      section.id === "incoming_call_entries_id"
        ? section.id
        : `${section.id}_id`;
    return newRow[key] === null;
  });
  useEffect(() => {
    setNote(
      bioNotes?.map((val) => {
        return {
          status: newRow?.notes?.includes(val.title) ? true : false,
          name: val.title,
        };
      })
    );
  }, [bioNotes]);

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  const handleViewImage = (path) => {
    SetViewImage({ ...viewImage, open: !viewImage?.open, path: path });
  };

  useEffect(() => {
    if (typeof newRow?.mobiles === "string") {
      setMobileValue(JSON.parse(newRow?.mobiles));
    } else {
      setMobileValue(newRow?.mobiles);
    }
  }, [newRow]);

  const restoreBioUser = async (obj) => {
    obj.id = newRow?.id;
    let res = await restoreBiodataUser(obj.id);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    setNewRow({ ...newRow, ...obj });
  };

  useEffect(() => {
    currentDate();
  }, []);

  const currentDate = () => {
    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    setDate(today);
  };

  const handleClose = () => {
    setOpen(!open);
    getBiodataDetail();
  };

  const englishName = newRow?.BiodataImageUploadByStaffName?.name?.english;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const handleCopyMobile = (i) => {
    copy(i);
    enqueueSnackbar("Copied!");
  };
  const handleCopyName = () => {
    copy(newRow.name);
    enqueueSnackbar("Copied!");
  };
  const onStatus = async (obj) => {
    setNewRow({ ...newRow, ...obj });
    obj.id = bioData?.id;
    let res = await updateBiodata(obj);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
  };
  //call history
  const handleOpenHistory = () => {
    setHistoryPopupOpen(true);
  };

  const handleCloseHistory = () => {
    setHistoryPopupOpen(false);
  };

  //np history pop
  const handleNpOpenHistory = () => {
    setNpHistoryPopupOpen(true);
  };

  const handleNpCloseHistory = () => {
    setNpHistoryPopupOpen(false);
  };

  //nc history pop
  const handleNcOpenHistory = () => {
    setNcHistoryPopupOpen(true);
  };

  const handleNcCloseHistory = () => {
    setNcHistoryPopupOpen(false);
  };
  //age calculator
  const calculate_age = (dob) => {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - dob);
  };
  // send whatsapp msg
  const whatsappNow = (mobile) => {
    window.location.href = "https://wa.me/91" + `${maskNumber(mobile)}`;
  };
  const onDelete = async () => {
    let obj = { id: bioData?.id };
    let res = await deleteBiodata(obj);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
      getBiodataDetail();
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
  };

  const handleProfileDone = async () => {
    let obj = {
      // verify_process: "Done",
      id: newRow?.id,
      is_online_user: 1,
    };
    const res = await updateBiodata(obj);
    if (res?.data?.status) {
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
      } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    }
  };

  return (
    <div
      className={`${
        newRow?.OnlineUserShadiAndDeleted
          ? "offlineCard-Isonline"
          : "offlineCard"
      } m-2`}
    >
      {
        newRow?.OnlineUserShadiAndDeleted &&
        newRow?.OnlineUserShadiAndDeleted?.deleted_at === null &&
        newRow?.OnlineUserShadiAndDeleted?.shadi_done === null ? (
          <div className="goldenBadge">
            <Iconify icon="entypo:shield" />
            On App
          </div>
        ) : newRow?.OnlineUserShadiAndDeleted?.deleted_at &&
          newRow?.OnlineUserShadiAndDeleted?.shadi_done === null ? (
          <div className="goldenBadge" style={{ color: "#FF3030" }}>
            <Iconify
              icon="material-symbols:close-small"
              style={{
                background: "#FF3030",
                color: "#fff",
                borderRadius: "50%",
              }}
            />
            Deleted
          </div>
        ) : newRow?.OnlineUserShadiAndDeleted?.shadi_done ? (
          <div className="goldenBadge" style={{ color: "#32BE8F" }}>
            <Iconify
              icon="material-symbols:check-small"
              style={{
                background: "#32BE8F",
                color: "#fff",
                borderRadius: "50%",
              }}
            />
            Shadi Done
          </div>
        ) : null // Add this to handle other cases
      }
      <div
        className="d-flex justify-content-between primaryBg light"
        style={{
          padding: "8px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <div
          className={`d-flex align-items-center ${
            newRow?.is_online_user ? "ms-4" : ""
          }`}
        >
          {/* <div>
            {check && (
              <Checkbox
                size="small"
                checked={selected}
                onChange={() => handleToggleOne(newRow.id)}
              />
            )}
          </div> */}
          <div>
            {newRow?.id && (
              <Typography
                className={`${newRow?.is_online_user ? "ms-4" : ""}`}
                variant="subtitle1"
              >
                {" "}
                #{newRow?.id} &nbsp;
              </Typography>
            )}
            {newRow?.name && (
              <Typography
                variant="subtitle1"
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  handleCopyName(newRow?.name);
                }}
              >
                <AccountCircleIcon
                  className="fs-16"
                  style={{ marginRight: "5px" }}
                />
                {newRow?.name}{" "}
                <ContentCopyOutlinedIcon className="fs-16 ms-2" />
              </Typography>
            )}
            <div className="fs-12">
              <span
                style={{
                  color: "#cb0a0a",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
              >
                {newRow?.gender}
              </span>
              <span>
                {" "}
                | {newRow?.dob && calculate_age(new Date(newRow?.dob))}
              </span>
              <span> | {newRow?.sect}</span>
              <span> | {newRow?.marital_status}</span>
              <span> | {newRow?.employed_in}</span>
              <span> | {newRow?.physical_status}</span>
              <span> | {newRow?.state}</span>
            </div>
          </div>
        </div>

        <div>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleToggleAll(newRow?.id)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: selectedId?.find((i) => i === newRow?.id)
                ? "#fff"
                : "#32be8f",
              border: "1px solid #fff",
              borderRadius: "5px",
              color: selectedId?.find((i) => i === newRow?.id)
                ? "#32be8f"
                : "#fff",
            }}
          >
            {selectedId?.find((i) => i === newRow?.id) ? "Unselect" : "Select"}{" "}
          </Button>
        </div>
      </div>

      {/* avalible cards show  */}
      <p
        style={{
          backgroundColor: "#50C878",
          color: "white",
          padding: "0 5px",
          wordWrap: "break-word",
          borderTop: "2px solid white",
          borderBottom: "2px solid white",
          borderLeft: "2px solid white",
          borderRight: "2px solid white",
        }}
      >
        {allNull
          ? "Available in - fresh card"
          : `Available in - ${availableSections.join(" | ")}`}
      </p>
      {newRow?.biodata_image !== null && (
        <div
          style={{
            backgroundColor: "lightgreen",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
          }}
        >
          <div>
            <span style={{ color: "white", fontWeight: "300" }}>
              Uploaded by:
            </span>
            <span style={{ color: "white", fontWeight: "300" }}>
              {newRow?.BiodataImageUploadByStaffName?.name?.english}
            </span>
          </div>
          <div>
            <span style={{ color: "white", fontWeight: "300" }}>
              {newRow?.upload_type === "bulk"
                ? "Bulk"
                : newRow?.upload_type === "instant"
                ? "Instant"
                : newRow?.upload_type === "biodata uploading"
                ? "Biodata Uploading"
                : ""}
            </span>
          </div>
        </div>
      )}
      <div className="py-2 px-3">
        {/* image section start */}
        <div className="d-flex justify-content-between">
          <div>
            {newRow?.image ? (
              <div style={{ display: "flex" }}>
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                <Image
                  alt={newRow.value}
                  src={`${constant.appBaseUrl}/users/offline_users/${newRow?.image}`}
                  sx={{ borderRadius: 1 }}
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: 8,
                    objectFit: "contain",
                  }}
                  onClick={() => {
                    handleViewImage(
                      `${constant.appBaseUrl}/users/offline_users/${newRow?.image}`
                    );
                  }}
                />
                <ContentCopyOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    copyImageById(
                      `${constant.appBaseUrl}/users/offline_users/${newRow?.image}`
                    )
                  }
                  className="fs-16 ms-2"
                />
              </div>
            ) : (
              <Image
                src={blankUSer}
                alt="about-vision"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: 8,
                  objectFit: "contain",
                }}
              />
            )}
          </div>
          {newRow?.whatsapp_status && (
            <div>
              <p
                style={{ fontSize: 12, color: "blue", fontWeight: 500 }}
                className="pb-0 mb-0"
              >
                {newRow?.whatsapp_number && (
                  <p className="m-0">
                    (W-No.) - {newRow?.whatsapp_number} (
                    {newRow?.whatsapp_type === "Business"
                      ? "B"
                      : newRow?.whatsapp_type === "Whatsapp"
                      ? "W"
                      : ""}
                    )
                  </p>
                )}
              </p>
              <p style={{ fontSize: 12, color: "blue" }} className="pb-1 m-0">
                {newRow?.whatsappBy?.name?.english
                  ? "(W-Staff) - " +
                    newRow?.whatsappBy?.name?.english +
                    " " +
                    moment(newRow?.whatsapp_date_time)?.format("DD/MM/YY") +
                    " , " +
                    moment(newRow?.whatsapp_date_time)?.format("HH:mm")
                  : newRow?.whatsappBy?.name?.english}
              </p>
            </div>
          )}
        </div>
        {/* image section end */}
        <div>
          {mobileValue && mobileValue?.length > 0
            ? mobileValue?.map((i) => {
                return (
                  <div className="displayBtwPro" style={{ height: "50px" }}>
                    <div>
                      <Button
                        className="roundBtn"
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleCopyMobile(
                            maskNumber(typeof i === "object" ? i?.mobile : i)
                          )
                        }
                        // onClick={() => handleCopyMobile(maskNumber(i?.mobile))}
                      >
                        {maskNumber(typeof i === "object" ? i?.mobile : i)}
                        {/* {maskNumber(i?.mobile)} */}
                        <ContentCopyOutlinedIcon className="fs-16 ms-2" />
                      </Button>
                    </div>
                    <div className="my-1 align-items-center d-flex">
                      <button
                        className="WtsBtn"
                        style={{
                          borderColor: "blue",
                          color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                          background: newRow?.whatsapp_status
                            ? "#525FFE"
                            : "transparent",
                        }}
                        onClick={() => {
                          onStatus({
                            whatsapp_status: !newRow?.whatsapp_status,
                            whatsapp_date_time: date,
                            whatsapp_action_id: contxt?.user?.id,
                          });
                        }}
                      >
                        WA
                      </button>
                      <button
                        className="WtsBtn"
                        style={{ borderColor: "#32BE8F", color: "#32BE8F" }}
                        onClick={() => {
                          whatsappNow(newRow?.mobile);
                        }}
                      >
                        <WhatsAppIcon />
                      </button>
                    </div>
                  </div>
                );
              })
            : newRow?.mobile && (
                <div className="displayBtwPro" style={{ height: "50px" }}>
                  <div>
                    <Button
                      className="roundBtn"
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        handleCopyMobile(maskNumber(newRow?.mobile))
                      }
                    >
                      {maskNumber(newRow?.mobile)}
                      <ContentCopyOutlinedIcon className="fs-16 ms-2" />
                    </Button>
                  </div>
                  <div className="my-1 align-items-center d-flex">
                    <button
                      className="WtsBtn"
                      style={{
                        borderColor: "blue",
                        color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                        background: newRow?.whatsapp_status
                          ? "#525FFE"
                          : "transparent",
                      }}
                      onClick={() => {
                        onStatus({
                          whatsapp_status: !newRow?.whatsapp_status,
                          whatsapp_date_time: date,
                          whatsapp_action_id: contxt?.user?.id,
                        });
                      }}
                    >
                      WA
                    </button>
                    <button
                      className="WtsBtn"
                      style={{ borderColor: "#32BE8F", color: "#32BE8F" }}
                      onClick={() => {
                        whatsappNow(newRow?.mobile);
                      }}
                    >
                      <WhatsAppIcon />
                    </button>
                  </div>
                </div>
              )}
        </div>

        {/* Third section start */}
        <div className="displayBtwPro" style={{ marginLeft: "-16px" }}>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.total_count_calls}
            </Button>
            <Switch
              checked={newRow?.call_status ? true : false}
              onChange={() => {
                onStatus({
                  call_status: newRow?.call_status ? false : true,
                  call_date_time: date,
                  calling_action_id: contxt?.user?.id,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              Call
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.total_count_not_pick}
            </Button>
            <Switch
              checked={newRow?.pickup_status ? true : false}
              onChange={() => {
                onStatus({
                  pickup_status: newRow?.pickup_status ? false : true,
                  pickup_date_time: date,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              NP
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.total_count_not_received}
            </Button>
            <Switch
              checked={newRow?.not_connected_status ? true : false}
              onChange={() => {
                onStatus({
                  not_connected_status: newRow?.not_connected_status
                    ? false
                    : true,
                  not_connected_date_time: date,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              NC
            </Typography>
          </Box>
        </div>
        {/* Third section end  */}
        {/* Fourth Section start */}
        {newRow?.call_status && (
          <div>
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "10px" }}
            >
              <Button
                size="small"
                variant={newRow?.samband_click ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    samband_click: !newRow?.samband_click,
                  });
                }}
              >
                Sambandh
              </Button>
              <Button
                size="small"
                variant={newRow?.share_status ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    share_status: !newRow?.share_status,
                  });
                }}
              >
                Share
              </Button>
              <Button
                size="small"
                variant={newRow?.later_status ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    later_status: !newRow?.later_status,
                    later_date_time: new Date(),
                  });
                }}
              >
                Later
              </Button>
              <Button
                size="small"
                variant={newRow?.special ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    special: !newRow?.special,
                    special_date_time: new Date(),
                  });
                }}
              >
                Special
              </Button>
              <Button
                size="small"
                variant={
                  newRow?.not_interested_status ? "contained" : "outlined"
                }
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    not_interested_status: !newRow?.not_interested_status,
                    not_interested_date_time: new Date(),
                  });
                }}
              >
                Not Interested
              </Button>
              <Button
                size="small"
                variant={newRow?.hindu_cast ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    hindu_cast: !newRow?.hindu_cast,
                    hindu_cast_date_time: new Date(),
                  });
                }}
              >
                Hindu
              </Button>
              <Button
                size="small"
                variant={newRow?.other_cast ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    other_cast: !newRow?.other_cast,
                    other_cast_date_time: new Date(),
                  });
                }}
              >
                Other Cast
              </Button>
              <Button
                size="small"
                variant={newRow?.shadi_done_status ? "contained" : "outlined"}
                className="btnPadding"
                onClick={async () => {
                  // Update status with onStatus
                  onStatus({
                    shadi_done_status: !newRow?.shadi_done_status,
                    shadi_done_date_time: new Date(),
                  });

                  // Call the bioDataShadiDoneMove API and pass bioData.id
                  try {
                    const response = await bioDataShadiDoneMove(bioData?.id);
                    if (response?.status === 200) {
                      console.log("API call successful:", response.data);
                    } else {
                      console.error(
                        "API call failed with status:",
                        response?.status
                      );
                    }
                  } catch (error) {
                    console.error("Error in API call:", error);
                  }
                }}
              >
                Shadi Done
              </Button>

              <Button
                size="small"
                variant={
                  newRow?.online_by_other_mobile_avai ? "contained" : "outlined"
                }
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    online_by_other_mobile_avai:
                      !newRow?.online_by_other_mobile_avai,
                    online_by_other_mobile_avai_date_time: new Date(),
                  });
                }}
              >
                On App
              </Button>
              <Button
                size="small"
                variant={newRow?.different_language ? "contained" : "outlined"}
                className="btnPadding"
                onClick={() => {
                  onStatus({
                    different_language: !newRow?.different_language,
                    different_language_date_time: new Date(),
                  });
                }}
              >
                Diff Language
              </Button>
            </div>
            {/* rating section start */}
            <div className="my-2">
              {ratingArray?.map((i) => (
                <Button
                  className="ratingSec"
                  key={i?.key}
                  variant={newRow?.rating === i?.key ? "contained" : "outlined"}
                  size="small"
                >
                  {i?.title}
                </Button>
              ))}
            </div>
            {/* rating section end */}

            {/* Sambandh section preview */}
            {newRow?.BioSambandh?.length > 0 && (
              <>
                {" "}
                {newRow?.BioSambandh?.map((val, ind) => {
                  return (
                    <div>
                      {val?.type == "0" && (
                        <div className="row align-items-center">
                          <div className="col-10 pe-0">
                            <p className="fs-12 darkText border-bottom px-1 ">
                              <span style={{ color: "#eb445a" }}>
                                <b>SF </b>{" "}
                              </span>
                              {val?.created_for ? ": " + val?.created_for : ""}
                              {val?.name ? "- " + val?.name : ""}
                              {val?.mobile
                                ? "- " + maskNumber(val?.mobile)
                                : ""}
                              {val?.relative_name.trim()
                                ? "- " + val?.relative_name
                                : ""}
                              {val?.age ? "- " + val?.age : ""}
                              {val?.employed_in ? "- " + val?.employed_in : ""}
                              {val?.marital_status
                                ? "- " + val?.marital_status
                                : ""}
                              {val?.sect ? "- " + val?.sect : ""}
                              {val?.state ? "- " + val?.state : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {newRow?.BioSambandh &&
                  newRow?.BioSambandh?.map((val, ind) => {
                    return (
                      <div>
                        {val?.type == "1" && (
                          <div className="row align-items-center">
                            <div className="col-10">
                              <p className="fs-12 darkText border-bottom px-1 ">
                                <span style={{ color: "#eb445a" }}>
                                  <b>OF </b>{" "}
                                </span>
                                {val?.created_for
                                  ? ": " + val?.created_for
                                  : ""}
                                {val?.name ? ": " + val?.name : ""}
                                {val?.mobile
                                  ? ": " + maskNumber(val?.mobile)
                                  : ""}
                                {val?.relative_name
                                  ? ": " + val?.relative_name
                                  : ""}
                                {val?.employed_in
                                  ? "- " + val?.employed_in
                                  : ""}
                                {val?.marital_status
                                  ? "- " + val?.marital_status
                                  : ""}
                                {val?.sect ? "- " + val?.sect : ""}
                                {val?.state ? "- " + val?.state : ""}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </>
            )}
            <div className="row ">
              <div className="col-12">
                {newRow?.partner_preference ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Partner Preference : </b>
                    {newRow?.partner_preference}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                {newRow?.different_language ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Different Language : </b>
                    {newRow?.different_language} |{" "}
                    {newRow?.different_language_notes}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                {newRow?.not_interested_notes ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Not Interested Reason : </b>
                    {newRow?.not_interested_notes}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* notes show */}
            <div className="row">
              {newRow?.other ? (
                <div className="col-12 fs-14" style={{ color: "#525ffe" }}>
                  {newRow?.other
                    ? "Notes : " + newRow?.other + newRow?.notes
                    : "" + <br />}
                  <span className="fs-12 fw-500" style={{ color: "red" }}>
                    {" "}
                    [
                    {newRow?.notes_date_time
                      ? moment(newRow?.notes_date_time)?.format("DD-MM-YY") +
                        " , " +
                        moment(newRow?.notes_date_time)?.format("HH:mm")
                      : ""}
                    ]
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* notes show */}
            <div className="row ">
              {(newRow?.special || newRow?.special_notes) && (
                <div className="col-12 fs-14">
                  <span style={{ color: "#525ffe" }}>
                    {" "}
                    {newRow?.special
                      ? "Special Share : " + newRow?.special
                      : ""}{" "}
                    |{" "}
                  </span>
                  <span style={{ color: "#EB445A" }}>
                    {newRow?.special_notes ? newRow?.special_notes : ""}
                  </span>
                </div>
              )}
            </div>
            {/* notes show */}
            <div className="row">
              {newRow?.normal_share && (
                <div className="col-12">
                  {newRow?.normal_share
                    ? "Share : " + newRow?.normal_share
                    : "" + <br />}
                </div>
              )}
            </div>

            {/* followup section */}
            {newRow?.later_status && (
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-14">
                  <span style={{ color: "red" }}>Follow up :</span>{" "}
                  {newRow?.later_date_time ? (
                    <span style={{ fontSize: "13px" }}>
                      Date :{" "}
                      {moment(newRow?.later_date_time).format("YYYY-MM-DD")}
                    </span>
                  ) : (
                    <span>Date : --- </span>
                  )}
                </div>
                <div className="fs-14">
                  {newRow?.later_date_time ? (
                    <span style={{ fontSize: "13px" }}>
                      Time : {moment(newRow?.later_date_time).format("HH:mm a")}
                    </span>
                  ) : (
                    <span>Time : ---</span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* Entry and update date time start */}
        <div className="displayBtwPro">
          {matchingObject && matchingObject.length > 0 && (
            <span className="fs-14">
              <span className="fw-500" style={{ color: "red" }}>
                Entry by :-
              </span>
              {matchingObject.map((el) => el?.nameEnglish)}(
              {`${moment(newRow?.created_at).format("DD/MM/YY, hh:mm")}`})
            </span>
          )}
          {updateIdName && updateIdName.length > 0 && (
            <span className="fs-14">
              <span className="fw-500" style={{ color: "red" }}>
                {" "}
                Updated by :-
              </span>
              {updateIdName.map((el) => el?.nameEnglish)}(
              {`${moment(newRow?.updated_at).format("DD/MM/YY, hh:mm")}`})
            </span>
          )}
        </div>
        {/* Entry and update date time end */}

        <div>
          <hr />
          {/* Share to section */}
          <div className="ShareSec">
            {newRow?.share_section ? (
              <p className="m-0">{newRow?.share_section}</p>
            ) : (
              <span>Share to</span>
            )}
          </div>

          {/* Add Pay/Bio/Pic section */}
          <div className="displayBtwPro">
            <Box display="flex" alignItems="center">
              <Switch
                checked={newRow?.biodata_image || newRow?.image ? true : false}
              />
              <Typography
                variant="subtitle2"
                className="primaryColor fw-500 primaryColor"
              >
                Add Bio/Pic
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Switch
                checked={newRow?.online_user_payment_status ? true : false}
              />
              <Typography
                variant="subtitle2"
                className="primaryColor fw-500 primaryColor"
              >
                Add Pay
              </Typography>
            </Box>
          </div>
          <div className="row py-2">
            <div className="col-6">
              {newRow?.biodata_image || newRow?.image ? (
                <div className="row">
                  <div className="col-12">
                    {newRow?.biodata_image ? (
                      <div style={{ display: "flex" }}>
                        <canvas
                          ref={canvasRef}
                          style={{ display: "none" }}
                        ></canvas>
                        <Image
                          alt={newRow.value}
                          src={`${constant.appBaseUrl}/users/offline_users/${newRow?.biodata_image}`}
                          sx={{ borderRadius: 1 }}
                          style={{
                            width: "120px",
                            height: 100,
                            borderRadius: 8,
                            objectFit: "fill",
                          }}
                          onClick={() => {
                            handleViewImage(
                              `${constant.appBaseUrl}/users/offline_users/${newRow?.biodata_image}`
                            );
                          }}
                        />
                        <ContentCopyOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            copyImageById(
                              `${constant.appBaseUrl}/users/offline_users/${newRow?.biodata_image}`
                            )
                          }
                          className="fs-16 ms-2"
                        />
                      </div>
                    ) : (
                      <Image
                        src={blankUSer}
                        alt="about-vision"
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                      />
                    )}
                  </div>
                  <div className="col-12">
                    <div>
                      {newRow?.image ? (
                        <div style={{ display: "flex" }}>
                          <canvas
                            ref={canvasRef}
                            style={{ display: "none" }}
                          ></canvas>
                          <Image
                            alt={newRow.value}
                            src={`${constant.appBaseUrl}/users/offline_users/${newRow?.image}`}
                            sx={{ borderRadius: 1 }}
                            style={{
                              width: "120px",
                              height: 100,
                              borderRadius: 8,
                              objectFit: "fill",
                            }}
                            onClick={() => {
                              handleViewImage(
                                `${constant.appBaseUrl}/users/offline_users/${newRow?.image}`
                              );
                            }}
                          />
                          <ContentCopyOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              copyImageById(
                                `${constant.appBaseUrl}/users/offline_users/${newRow?.image}`
                              )
                            }
                            className="fs-16 ms-2"
                          />
                        </div>
                      ) : (
                        <Image
                          src={blankUSer}
                          alt="about-vision"
                          style={{
                            width: "120px",
                            height: 100,
                            borderRadius: 8,
                            objectFit: "fill",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-6">
              {newRow?.online_user_payment_status ? (
                <div>
                  <div className="ShareSec">
                    {" "}
                    {newRow?.online_user_package ? (
                      <p className="m-0">{newRow?.online_user_package}</p>
                    ) : (
                      <span>Select...</span>
                    )}{" "}
                  </div>
                  <p className="my-2" style={{ borderBottom: "1px solid" }}>
                    {" "}
                    {newRow?.online_user_done_amount ? (
                      <p className="m-0">{newRow?.online_user_done_amount}</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <div>
                    {newRow?.online_user_payment_screenshot ? (
                      <Image
                        alt={newRow.value}
                        src={`${constant.appBaseUrl}/users/payment_screen_shot/${newRow?.online_user_payment_screenshot}`}
                        sx={{ borderRadius: 1 }}
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                        onClick={() => {
                          handleViewImage(
                            `${constant.appBaseUrl}/users/payment_screen_shot/${newRow?.online_user_payment_screenshot}`
                          );
                        }}
                      />
                    ) : (
                      <Image
                        src={blankUSer}
                        alt="about-vision"
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* notes show od selftask detail */}
          {Array.isArray(newRow?.self_task_detail) &&
            newRow.self_task_detail.length > 0 && (
              <div>
                {newRow.self_task_detail.map((task, index) => (
                  <div key={index}>
                    {(task.task_type === "master_task" ||
                      task.task_type === "self task" ||
                      task.task_type === "doubletick") && (
                      <p style={{ fontSize: "12px", margin: "4px 0" }}>
                        Task Assign=
                        <span style={{ fontWeight: "500" }}>
                          Kisne Assign Kiya-{task.task_create_by_name}
                        </span>{" "}
                        |
                        <span style={{ fontWeight: "500" }}>
                          Kisko Assign kiya-{task.task_create_of_name}
                        </span>{" "}
                        |
                        <span>
                          {moment(task.task_created_at).format(
                            "DD-MM-YY, h:mm a"
                          )}
                        </span>{" "}
                        |<span>Task ka Type={task.task_type}</span>
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}

          {/* Biodata Notes */}
          {newRow?.BiodataUserNote &&
            newRow?.BiodataUserNote.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span style={{ color: "blue" }}>Biodata Note :-</span>
                    {val?.created_for}
                    {val.other}
                    <span
                      className="fs-14"
                      style={{ color: "red", fontWeight: "500" }}
                    >
                      {val?.notes}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.NoteByStaffBiodata?.name?.english}
                        {`${moment(val?.notes_date_time).format(
                          "DD/MM/YY, hh:mm"
                        )}`}
                        {val?.NoteByStaffBiodata?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {/* Whatsapp Response */}
          {newRow?.BiodataWhatsRes?.BiodataWhatsReserHist?.length > 0 &&
            newRow?.BiodataWhatsRes?.BiodataWhatsReserHist?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Whatsapp Response :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.BiodataWhatsRes ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.BiodataWhatsRes?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.BiodataWhatsRes?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.BiodataWhatsRes?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataWhatsRes?.BiodataCallWstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataWhatsRes?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.BiodataWhatsRes?.not_pickup_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.BiodataWhatsRes?.not_pickup_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataWhatsRes?.BiodataCallWstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataWhatsRes?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.BiodataWhatsRes?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.BiodataWhatsRes?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataWhatsRes?.BiodataCallWstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataWhatsRes?.normal_share && (
                <span style={{ color: "red" }}>
                  Whatsapp Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataWhatsRes?.normal_share}
                    {newRow?.BiodataWhatsRes?.share_notes}
                  </span>{" "}
                  &nbsp;
                </span>
              )}
              {newRow?.BiodataWhatsRes?.special_notes && (
                <span style={{ color: "red" }}>
                  Whatsapp Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataWhatsRes?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataWhatsRes?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataWhatsRes?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataWhatsRes?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataWhatsRes?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataWhatsRes?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataWhatsRes?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataWhatsRes?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataWhatsRes?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/*  whatsapp response end */}

          {/* group services */}
          {newRow?.BiodataGroupS?.BiodataGroupSer?.length > 0 &&
            newRow?.BiodataGroupS?.BiodataGroupSer?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Group Service :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.BiodataGroupS ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.BiodataGroupS?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.BiodataGroupS?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.BiodataGroupS?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataGroupS?.BiodataCallGstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataGroupS?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.BiodataGroupS?.not_pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.BiodataGroupS?.not_pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataGroupS?.BiodataCallGstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataGroupS?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.BiodataGroupS?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.BiodataGroupS?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataGroupS?.BiodataCallGstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataGroupS?.normal_share && (
                <span style={{ color: "red" }}>
                  Group Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataGroupS?.normal_share}
                    {newRow?.BiodataGroupS?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataGroupS?.special_notes && (
                <span style={{ color: "red" }}>
                  Group Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataGroupS?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataGroupS?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Different Language : -
                  <span className="textDark">
                    {newRow?.BiodataGroupS?.different_language_notes}
                  </span>{" "}
                </p>
              )}
              {newRow?.BiodataGroupS?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataGroupS?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataGroupS?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataGroupS?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataGroupS?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataGroupS?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* group note end */}

          {/* Paid Promotion */}
          {newRow?.BiodataPaidPa?.BiodataPaidPhistory?.length > 0 &&
            newRow?.BiodataPaidPa?.BiodataPaidPhistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Paid Promotion :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.BiodataPaidPa ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.BiodataPaidPa?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.BiodataPaidPa?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.BiodataPaidPa?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataPaidPa?.BiodataCallPstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataPaidPa?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.BiodataPaidPa?.not_pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.BiodataPaidPa?.not_pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataPaidPa?.BiodataCallPstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataPaidPa?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.BiodataPaidPa?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.BiodataPaidPa?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataPaidPa?.BiodataCallPstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataPaidPa?.normal_share && (
                <span style={{ color: "red" }}>
                  Paid Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataPaidPa?.normal_share}
                    {newRow?.BiodataPaidPa?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataPaidPa?.special_notes && (
                <span style={{ color: "red" }}>
                  Paid Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataPaidPa?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataPaidPa?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataPaidPa?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataPaidPa?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataPaidPa?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataPaidPa?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataPaidPa?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataPaidPa?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataPaidPa?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* paid note end */}

          {/* Social Media */}
          {newRow?.BiodataSocialM?.BiodataSocialHistory?.length > 0 &&
            newRow?.BiodataSocialM?.BiodataSocialHistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Social Media :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.BiodataSocialM ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.BiodataSocialM?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.BiodataSocialM?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.BiodataSocialM?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataSocialM?.BiodataCallSstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataSocialM?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.BiodataSocialM?.not_pickup_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.BiodataSocialM?.not_pickup_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataSocialM?.BiodataCallSstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataSocialM?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.BiodataSocialM?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.BiodataSocialM?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.BiodataSocialM?.BiodataCallSstaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.BiodataSocialM?.normal_share && (
                <span style={{ color: "red" }}>
                  Social Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataSocialM?.normal_share}
                    {newRow?.BiodataSocialM?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataSocialM?.special_notes && (
                <span style={{ color: "red" }}>
                  Social Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataSocialM?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.BiodataSocialM?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.BiodataSocialM?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataSocialM?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataSocialM?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataSocialM?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataSocialM?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.BiodataSocialM?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.BiodataSocialM?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* social note end */}
          {/* share card notes  */}
          <p className="m-0">
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: "red",
              }}
            >
              Share notes :{" "}
            </span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
              }}
            >
              {newRow?.STPNOTEINBD?.share_staff_comment}

              <div>
                <span>
                  [Time:{" "}
                  {newRow?.STPNOTEINBD?.share_staff_comment_date_time
                    ? moment(newRow?.share_staff_comment_date_time).format(
                        "DD-MM-YY, h:mm a"
                      )
                    : "No Date and Time"}
                  ] [Name:{" "}
                  {newRow?.STPNOTEINBD?.ShareCardNoteByStaff?.name?.english}]
                </span>
              </div>
            </span>
          </p>
          {/* Note Section */}
          <div>
            <Box
              display="flex"
              sx={{
                background: "#f2f2fe",
                color: "blue",
                padding: "5px",
                borderRadius: "8px",
              }}
            >
              <Typography variant="subtitle2"> Note : &nbsp;</Typography>
              {newRow.notes && (
                <Typography variant="subtitle2">{newRow.notes}</Typography>
              )}
            </Box>
          </div>
        </div>
        {/* Fourth Section end */}
        <box open={openPopover} onClose={handleClosePopover} arrow="right-top">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ my: 2 }}
          >
            {contxt?.user?.sections?.bioDataUsers?.delete && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleOpenConfirm();
                  handleClosePopover();
                  goToTop();
                }}
              >
                Delete
              </Button>
            )}
            {contxt?.user?.sections?.bioDataUsers?.update && (
              <RouterLink to={`/biodataupdate/${newRow?.id}`} target="_blank">
                <Button variant="contained" color="inherit" size="small">
                  Update
                </Button>
              </RouterLink>
            )}
            {contxt?.user?.sections?.bioDataUsers?.update && (
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setNoteModal(true);
                }}
              >
                Notes
              </Button>
            )}
            <Button variant="contained" size="small" onClick={restoreBioUser}>
              Restore
            </Button>
          </Stack>
        </box>

        {/* Create Profile */}
        {newRow?.createProfile && (
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Button
                  variant="outlined"
                  size={"small"}
                  className={"btnPadding"}
                  onClick={() => setOpen(!open)}
                >
                  Create Profile
                </Button>
                <Button
                  variant="outlined"
                  size={"small"}
                  className={"btnPadding"}
                  onClick={handleProfileDone}
                >
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </div>

      <Dialog
        open={historyPopupOpen}
        onClose={handleCloseHistory}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
          <table className="table">
            <thead>
              <tr>
                <th style={{ color: "#5858d9" }}>#</th>
                <th style={{ color: "#5858d9" }}>Staff Name</th>
                <th style={{ color: "#5858d9" }}>Call Time</th>
                <th style={{ color: "#5858d9" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {newRow?.call_historys &&
                newRow?.call_historys.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.staff_name}</td>

                    <td>
                      {" "}
                      {`${moment(item?.call_date_time).format(
                        "DD-MM-YY, hh:mm"
                      )}`}
                    </td>
                    <td>{item?.call_status ? "On" : "Off"}</td>
                  </tr>
                ))}
              {/* Add more rows if needed */}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>

      <Dialog
        Dialog
        open={viewImage?.open}
        onClose={() => {
          handleViewImage("");
        }}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleViewImage("");
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
        />
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                color: "#ed1c24",
                zIndex: "9",
                backgroundColor: "White",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              #Jain{newRow?.id}
            </p>
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
            <Image
              alt={newRow.value}
              src={viewImage.path}
              sx={{ borderRadius: 1 }}
              style={{
                width: 500,
                height: 600,
                borderRadius: 8,
                objectFit: "fill",
              }}
            />
          </div>
          {/* <Button variant="contained" onClick={copyImageWithId}>
            Copy Image with ID
          </Button>*/}
        </DialogContent>
      </Dialog>

      {/* np call history*/}
      <Dialog
        open={nphistoryPopupOpen}
        onClose={handleNpCloseHistory}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
          <table className="table">
            <thead>
              <tr style={{ color: "#5858d9" }}>
                <th style={{ color: "#5858d9" }}>#</th>
                <th style={{ color: "#5858d9" }}>Staff Name</th>
                <th style={{ color: "#5858d9" }}>Call Time</th>
                <th style={{ color: "#5858d9" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {newRow?.not_pick_call_history &&
                newRow?.not_pick_call_history.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.staff_name}</td>

                    <td>
                      {" "}
                      {`${moment(item.call_date_time).format(
                        "DD-MM-YY, hh:mm"
                      )}`}
                    </td>
                    <td>{item?.call_status ? "On" : "Off"}</td>
                  </tr>
                ))}
              {/* Add more rows if needed */}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>

      {/* nc call history  */}
      <Dialog
        open={nchistoryPopupOpen}
        onClose={handleNcCloseHistory}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
          <table className="table">
            <thead>
              <tr style={{ color: "#5858d9" }}>
                <th style={{ color: "#5858d9" }}>#</th>
                <th style={{ color: "#5858d9" }}>Staff Name</th>
                <th style={{ color: "#5858d9" }}>Call Time</th>
                <th style={{ color: "#5858d9" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {newRow?.not_received_call_history &&
                newRow?.not_received_call_history.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.staff_name}</td>

                    <td>
                      {" "}
                      {`${moment(item?.call_date_time).format(
                        "DD-MM-YY, hh:mm"
                      )}`}
                    </td>
                    <td>{item?.call_status ? "On" : "Off"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={noteModal}
        onClose={() => {
          setNoteModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setNoteModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Notes"}
            />

            <div>
              <FormControl
                sx={{ mb: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Grid container spacing={1}>
                    {notes?.map((val, i) => {
                      return (
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={val?.status}
                                onChange={(event) => {
                                  var tempNote = [...notes];
                                  tempNote[i].status = event.target.checked;
                                  setNote(tempNote);
                                  if (event.target.checked) {
                                    setNoteModalValue(
                                      noteModalValue + " | " + event.target.name
                                    );
                                  } else {
                                    setNoteModalValue(
                                      noteModalValue.replaceAll(
                                        " | " + event.target.name,
                                        " "
                                      )
                                    );
                                  }
                                }}
                                name={val?.name}
                              />
                            }
                            label={val?.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
              <TextField
                multiline
                fullWidth
                label="Note"
                placeholder="Type note here.."
                value={noteModalValue}
                onChange={(e) => {
                  setNoteModalValue(e.target.value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({ notes: noteModalValue });
                    setNewRow({ ...newRow, notes: noteModalValue });
                    setNoteModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
      <StaffToPayNewUser
        open={open}
        cardType={"biodata"}
        getPendingRavcListData={getPendingRavcListData}
        handleClose={handleClose}
        filterDetail={filterDetail}
      />
      <NewUser
        open={open}
        handleClose={handleClose}
        filterDetail={filterDetail}
      />
    </div>
  );
}
